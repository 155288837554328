
import React, { Component } from 'react';
import { Animated ,Platform,StyleSheet,TouchableOpacity} from 'react-native';
import { dimensions } from "../Dev";
class OverLay extends Component {
     constructor(props)
     {
         super(props);
         this.state={
            fade: new Animated.Value(0),
            isVisible:false,
         };
     };
     fadeMe(state_,toVal,duration_)
     {
        Animated.timing(state_, {
            toValue: toVal,
            duration: duration_,
            useNativeDriver:false
          }).start(()=>{
            toVal==0&&this.setState({isVisible:!this.state.isVisible})
          });
     }
     componentDidUpdate(prevProps)
     {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.props.isVisible?this.setState({isVisible:!this.state.isVisible},function(){this.fadeMe(this.state.fade,1,300)})
            :this.fadeMe(this.state.fade,0,300);
          }
     }
    render() {
        return (
            <>
             {this.state.isVisible&&<Animated.View style={[styles.OverLay,Platform.OS=='web'&&styles.paltWeb,{opacity:this.state.fade}]}>
                    <TouchableOpacity style={styles.onOverlayPress} activeOpacity={1} onPress={this.props.onOverlayPress}>
                             
                    </TouchableOpacity>
                    {this.props.children}
                </Animated.View>}
            </>
        );
    }
};
const styles = StyleSheet.create({
    OverLay:
    { 
       position:Platform.OS=='web'?"fixed":"absolute",
       width:dimensions.width,
       height:dimensions.height,
       minWidth:dimensions.width,
       maxWidth:dimensions.width,
       maxHeight:dimensions.height,
       minHeight:dimensions.height,
       top:0,
       right:0,
       backgroundColor:"#00000014",
       zIndex:99999,
    },
    paltWeb:
    {
       width:"100%",
       height:"100%",
       minWidth:"100%",
       maxWidth:"100%",
       maxHeight:"100%",
       minHeight:"100%",
    },
    onOverlayPress:
    {
      position:"absolute",
      height:"100%",
      width:"100%",
      left:0,
      top:0,
    },
});

export default OverLay 