export const INFOS = "INFOS";
export const TRACKS = "TRACKS";
export const TRACKSTATE = "TRACKSTATE";
export const ACPLAYER = "ACPLAYER";
export const DIMENSIONS = "DIMENSIONS";
export const SETUSER = "SETUSER"; 
export const SETCLIENT = "SETCLIENT"; 
export const SETSECRET= "SETSECRET"; 
export const SETLIVE = "SETLIVE"; 
export const SETPLAYLIST = "SETPLAYLIST"; 
export const SETCURRENT = "SETCURRENT"; 
