
import {SETUSER} from "../actions/Types"
const INTIAL_STATE=
{
    user:null,
}
export default (state=INTIAL_STATE,action)=>{

    switch (action.type) {
        case SETUSER:
            return {...INTIAL_STATE,user:action.user}
        default:
            return state;
    }
}