import React, { Component } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, NativeModules } from 'react-native';
var Aes = NativeModules.Aes;



class Testscreen extends Component {

  render() {
    return (
      <View style={styles.container}>
        <TouchableOpacity onPress={() => {
         /*  try {
            generateKey('RadioFactoryAesKey', 'salt', 5000, 256).then(key => {
              console.log('Key:', key)
              encryptData('Azerty@2021', key)
                .then(({ cipher, iv }) => {
                  console.log('Encrypted:', cipher)
                  console.log('iv:', iv)

                  Aes.decrypt(cipher, key, '')
                    .then(text => {
                      console.log('Decrypted:', text)
                    })
                    .catch(error => {
                      console.log(error)
                    })

                  Aes.hmac256(cipher, key).then(hash => {
                    console.log('HMAC', hash)
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            })
          } catch (e) {
            console.error(e)
          } */
        }}>
          <Text>
            Crypt
          </Text>

        </TouchableOpacity>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  container:
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex:1
  }
});
export default Testscreen
