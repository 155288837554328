import {ACPLAYER} from "../actions/Types"
const INTIAL_STATE=
{
    active:2,
    loader:false
}

export default (state=INTIAL_STATE,action)=>{

    switch (action.type) {
        case ACPLAYER:
            return {...INTIAL_STATE,active:action.active,loader:action.loader}
        default:
            return state;
    }
}