
import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const Arrow = (props) => {
    return (
        <View>
            <Svg  width="11.824" height="10.14" viewBox="0 0 11.824 10.14">
               <Path  d="M11.113,36.082a.845.845,0,0,0-.146-.011H2.625l.182-.085a1.692,1.692,0,0,0,.478-.338l2.339-2.339a.876.876,0,0,0,.123-1.121.846.846,0,0,0-1.269-.11l-4.23,4.23a.846.846,0,0,0,0,1.2h0l4.23,4.23a.846.846,0,0,0,1.269-.085.876.876,0,0,0-.123-1.121L3.289,38.186a1.692,1.692,0,0,0-.423-.309l-.254-.114H10.92a.876.876,0,0,0,.893-.711A.846.846,0,0,0,11.113,36.082Z" transform="translate(0 -31.842)" 
               fill={props.color?props.color:'#fff'} />
            </Svg>
        </View>
    );
};
export { Arrow }