
import React, { Component } from 'react';
import { View, ScrollView, StyleSheet, Text, Platform } from 'react-native';
import styleG from "../assets/style/styleG";
import Footer from '../components/Footer';
import Header from '../components/Header';
import {connect} from 'react-redux';
import  HTMLView from '../components/HTMLView';
import {apiUrl} from "../Dev";
import axios from 'axios';
import MessageBox from '../common/MessageBox';

class Privacy extends Component {
     constructor()
     {
         super();
         this.state=
         {
             title:'',
             content:'',
             showBoxMsg: false,
             boxMsg: "",
             boxColor: '#28a745',
         }
     }
     componentDidMount()
     {
        this.getPrivacy();
     }
     getPrivacy()
     {
        axios.get(apiUrl + 'legal-policy',{ 'headers': { "Authorization": "Basic cmZjYWRtaW46UEBzc2VSQw==" } })
        .then(response => {
            if (response.data.status == 'error') {
                this.setState({ boxColor: '#dc3545', boxMsg: response.data.messages.join('\n')}, () => {
                    this.setState({ showBoxMsg: true });
                });
            }
            else if (response.data.status == 'success') {
                
                    this.setState({title:response.data.title,content:response.data.content})
            } 
        }).catch((error) => {
            if(error.message=='Network Error')
            {
                this.setState({ boxColor: '#dc3545', boxMsg: 'Erreur réseau'}, () => {
                    this.setState({ showBoxMsg: true });
                })
            }
        });
     }
    render()
    {
        return (
            <>
             <MessageBox bg={this.state.boxColor} msg={this.state.boxMsg} show={this.state.showBoxMsg} closeBox={() => {
                    this.setState({ showBoxMsg: false });
                }} />
            <View style={[styleG.container_]}>
                {/* Header */}
                <Header activeOption={2} navigation={this.props.navigation} />
                {/* Header */}
                <View style={[styleG.container_]}>
                    <ScrollView style={[styles.Holder]}
                        stickyHeaderIndices={[0]}
                        showsVerticalScrollIndicator={true}
                    >
                        <View style={styles.titreHolder}>
                            <View style={[styleG.titreH4Border]}>
                                <Text style={[styleG.titreH4]}>{this.state.title}</Text>
                            </View>
                        </View>
    
                        <View style={styles.Content}>
                            <View style={styles.Desc}>
                            <HTMLView value={this.state.content} stylesheet={htmlStyle}/> 
                       </View>
                        </View>
                    </ScrollView>
                </View>
    
                <Footer navigation={this.props.navigation} />
            </View>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        user:state.user.user
    }
}
export default connect(mapStateToProps, {})(Privacy);

const styles = StyleSheet.create({
    Holder:
    {
        width: "100%",
        flex: 1,
        maxWidth: Platform.OS == "web" ? 700 : "100%",
        alignSelf: "center"
    },
    Content:
    {
        paddingBottom: 15,
        marginRight: 30,
        marginLeft: 30
    },
    titreHolder:
    {
        marginRight: 30,
        marginLeft: 30,
        paddingTop: 15,
        backgroundColor: '#FAF9FC',
        paddingBottom:20
    },
    Desc:
    {
        marginTop: 10
    },
});

const htmlStyle = StyleSheet.create({
  h1:{
    fontSize:20,
    fontWeight:'bold'
  },
  h2:{
    fontSize:17,
    fontWeight:'bold'
  },
  h3:{
    fontSize:16,
    fontWeight:'bold'
  },
});