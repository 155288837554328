import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const PowerOff = (props) => {
    return (
        <View>
            <Svg width={props.size ? props.size : 19} height={props.size ? props.size :19} fill={props.color ? props.color : "#fff"} viewBox="0 0 16 16">
                <Path d="M7.5 1v7h1V1h-1z" />
                <Path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
            </Svg>
        </View>
    );
};
export { PowerOff }
