import React, { useState, useCallback } from 'react'
import { View, StyleSheet,Text} from 'react-native';
import Slider_ from '../common/Slider_';
import Cropper from 'react-easy-crop';
import { Buttons } from '../common';
import getCroppedImg from './CropImage';
import {connect} from "react-redux";

const Cropper_Cu = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  
  const getCroppedImage =async (img) => {
    try {
      const croppedImage = await getCroppedImg(
        img,
        croppedAreaPixels,
        rotation
      )
      props.setUrl(croppedImage);
    } catch (e) {
      return null;
    }
  }


  return (
        <View style={[styles.cropView,{minWidth: props.dimensions.width > 600 ? 600 : '100%',}]}>
    <View style={styles.cropViewChild}>
    <Cropper
          image={props.image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={4 / 4}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
    </View>
    <View style={styles.cropViewFooter}>
        <View style={styles.cropViewItem}>
            <Text style={styles.cropViewlabel}>Zoom</Text>
            <View style={{flex:1}}>
            <Slider_
                valueChange={(val) => {
                    setZoom(val)
                }}
                simpleOne
                step={0.1}
                duration={3}
                min={1}
                value={zoom}
                trackStyle={slideStyle.track}
                railStyle={slideStyle.rail}
                handleStyle={slideStyle.handle}
            />
            </View>
            
        </View>
        <View style={[styles.cropViewItem,{marginTop:20,justifyContent:"flex-end"}]}>
                <View style={styles.btnHolder}>
                  <Buttons sm width={120} bg={'#dc3545'} onPress={() => { props.cancelpress();}} text={'Annuler'} />
                </View>
                <View style={[styles.btnHolder,{marginLeft:20}]}>
                  <Buttons sm width={120} bg={'#28a745'} onPress={() => { getCroppedImage(props.image)}} text={'sauvegarder'} />
                </View>
            </View>
    </View>
</View>

     );
}

const mapStateToProps = state => {
  return {
      dimensions: state.dimensions.dimensions,
  }
}
export default connect(mapStateToProps, {})(Cropper_Cu);

const styles = StyleSheet.create({
  cropView:
  {
      maxWidth: 600,
      backgroundColor: "#fff",
      height: "100%",
      alignSelf: "center",
      display: 'flex',
      flexDirection: "column",
      padding: 20
  },
  cropViewChild:
  {
      flex: 1,
  },
  cropViewFooter:
  {
      height: 150,
      marginTop: 20,
      paddingLeft:20,
      paddingRight:20
  },
  cropViewItem:
  {
      display:'flex',
      flexDirection:"row"
  },
  cropViewlabel:
  {
      fontSize:18,
      textTransform:"uppercase",
      color:"#181A20",
      marginRight:20,
      marginTop: 25
  }
});


const slideStyle =
{
    rail: {
        backgroundColor: '#ccc',
        height: 5,

    },
    track: {
        backgroundColor: '#DE1F41',
        height: 5,
    },
    handle: {
        width: 15,
        height: 15,
        backgroundColor: '#DE1F41',
        borderWidth: 0,
        marginTop: -5,
        outline: "none"
    }
}
