import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity,Platform, Text, Image } from 'react-native';


class PlayList extends Component {
   constructor(props) {
        super(props);
        this.state = {
            imgHeigth: 40,
        }
    }

    render() {
        return (
            <View  /* dataSet={{item:'boxScale'}} */ style={[styles.playList,{ width: this.props.rightSize},this.props.current && styles.current]} >
            <TouchableOpacity style={styles.able} onPress={this.props.onPress}> 
            </TouchableOpacity>
                 <Image 
                      onLayout={(event) => {
                        this.setState({ imgHeigth: event.nativeEvent.layout.width });
                    }}
                    source={{uri: this.props.cover}}
                    style={[{height:this.state.imgHeigth},styles.cover]}
                  />
                 <Text style={[styles.listName]} numberOfLines={1}>{ this.props.name }</Text>
                 <Text style={[styles.listCount,this.props.current && styles.white]} numberOfLines={1}>{ this.props.count } Épisodes</Text>
            </View>
        );
    }
};
const styles = StyleSheet.create({
  playList:
  {
    borderRadius: 10,
    borderWidth: 4,
    borderColor: "#e7e7e74a",
    padding:15,
    overflow: "hidden",
    marginTop:15,
    backgroundColor: '#fff'
  },
  current:{
    backgroundColor: '#d5d5d5',
    borderColor: '#efefef'
  },
  white:{
    color:'#181F2C',
    fontWeight:'bold'
  },
  cover:{
    width:'100%',
    resizeMode:'cover',
    borderRadius: 5,
    backgroundColor:'#fff'
  },
  listName:
  {
    fontSize:12,
    color:'#181F2C',
    paddingTop:10
  },
   listCount:
  {
    fontSize:9,
    color:'#737992',
    paddingTop:3
  },
  able:
    {
        width: "100%",
        height: "100%",
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex:100
    },
});

export { PlayList }