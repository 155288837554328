const State = null,Capability=null;
const TrackPlayer = {
    playerState : false,
    player: new Audio(),
    isUrl:false,
    current:'',
    load(url) {
        return new Promise((resolve, reject) => {
            try {
                this.player.src = url;
                this.isUrl=true,
                resolve();
            } catch (e) {
                reject();
            }
        })
    },
    play(url="") {
        return new Promise((resolve, reject) => {
            try {
                if(url!="")
                  {
                    this.player.src=""
                    this.player.src=url;
                  }
                  this.player.play();
                  this.playerState=true;
                  resolve();
            } catch (error) {
               reject();
            }
        });
    },
    pause(is_live=false) {
        try {
            this.player.pause();
            this.playerState=false;
        } catch (error) {
           
        }
    },
    stop() {
        try {
            this.player.src="";
            this.playerState=false;
        } catch (error) {
            
        }
    },
    isPlay()
    {
       return this.playerState;
    },
    isUrlSet()
    {
       return this.isUrl;
    },
    setVolume(vol)
    {
        this.player.volume=1-vol;
    },
    addEvent_(evnt_,fnEvnt)
    {
        this.player.addEventListener(evnt_,fnEvnt);
    },
    getPosition()
    {
        try {
          return this.player.currentTime;
        } catch (error) {
            return 0;
        }
    },
    seekTo(val)
    {
        try {
            this.player.currentTime=val;
          } catch (error) {
              return 0;
          }  
    },
}

export default TrackPlayer;
export {
    State,
    Capability
}