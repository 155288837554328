
import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const NewsIcon = (props) => {
    return (
        <View>
            <Svg  width={props.size?props.size:19.866} height={props.size?props.size:19} viewBox="0 0 19.866 19">
                <Path  d="M15.218,33.45V16.881a.6.6,0,0,0-.6-.595H.6a.6.6,0,0,0-.6.595v15.38a2.98,2.98,0,0,0,2.98,2.973H15.816A2.953,2.953,0,0,1,15.218,33.45ZM3.179,27.7H5.086a.595.595,0,1,1,0,1.189H3.179a.595.595,0,1,1,0-1.189Zm-.6-1.942a.6.6,0,0,1,.6-.595H5.086a.595.595,0,1,1,0,1.189H3.179A.6.6,0,0,1,2.583,25.76Zm9.5,6.62h-8.9a.595.595,0,1,1,0-1.189h8.9a.595.595,0,1,1,0,1.189Zm.6-3.132a.6.6,0,0,1-.6.595H7.629a.6.6,0,0,1-.6-.595v-4.44a.6.6,0,0,1,.6-.595h4.45a.6.6,0,0,1,.6.595Zm-.6-6.382h-8.9a.595.595,0,1,1,0-1.189h8.9a.595.595,0,1,1,0,1.189Zm0-2.537h-8.9a.595.595,0,1,1,0-1.189h8.9a.595.595,0,1,1,0,1.189Z" transform="translate(0 -16.286)" fill={props.color?props.color:'#e5e5e5'} />
                <Path d="M207,246.286h2.987v2.987H207Z" transform="translate(-198.874 -237.019)" fill={props.color?props.color:'#e5e5e5'} />
                <Path  d="M416.051,120.286h-3.059c0,12.379,0,11.983,0,11.983a1.8,1.8,0,0,0,3.6-.04s0,.273,0-11.4A.546.546,0,0,0,416.051,120.286Z" transform="translate(-396.731 -114.96)" fill={props.color?props.color:'#e5e5e5'}/>
            </Svg>
        </View>
    );
};
export { NewsIcon }