
import React from 'react';
import { View, StyleSheet, TouchableOpacity,Text,ActivityIndicator } from 'react-native';

const  Buttons = (props) => {
  return (
     <View style={[styles.buttonHolder,
      {width:props.width?props.width:"100%", 
      backgroundColor:props.bg?props.bg:"#212121",
      height:props.sm?30:45,
      opacity:props.disabled?0.6:1
      }]}>
        <TouchableOpacity disabled={props.disabled} style={styles.able} activeOpacity={0.9} onPress={props.onPress}>
                 {!props.loading?<Text style={[styles.buttonText,{fontSize:props.sm?12:16}]}>{props.text}</Text>:<ActivityIndicator size="small" color="#fff"/>}
        </TouchableOpacity>
     </View>
  );
};
const styles = StyleSheet.create({
    
  buttonHolder:
  {
      display:"flex",
      alignItems:'center',
      justifyContent:"center",
      flexDirection:"row",
      borderRadius:8,
      overflow:"hidden",
      alignSelf:'baseline',
      paddingLeft:25,
      paddingRight:25
  },
  able:
    {
      width:"100%",
      height:"100%",
      display:"flex",
      alignItems:'center',
      justifyContent:"center",
    },
    buttonText:{
      fontWeight:"500",
      color:'#fff',
      textTransform:"uppercase",
  }

  });

export { Buttons }