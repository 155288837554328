import {INFOS,SETCLIENT,SETUSER,TRACKS,TRACKSTATE,SETCURRENT,ACPLAYER,DIMENSIONS,SETLIVE,SETSECRET,SETPLAYLIST} from './Types';
import { Dimensions,Platform} from 'react-native';
export const setInfos = (Infos)=> {
    return (dispatch)=> {
        dispatch({ type:INFOS,Infos});
    };
};

export const setTracks = (tracks)=> {
    return (dispatch)=> {
        dispatch({ type:TRACKS,tracks});
    };
};
export const setLiveTrack = (liveTrack)=> {
    return (dispatch)=> {
        dispatch({ type:SETLIVE,liveTrack});
    };
};
export const setTrackState = (trackStatus)=> {
    return (dispatch)=> {
        dispatch({ type:TRACKSTATE,trackStatus});
    };
};
export const setCurrentTrack = (current)=> {
    return (dispatch)=> {
        dispatch({ type:SETCURRENT,current});
    };
};

export const setSecrets = (secret)=> {
    return (dispatch)=> {
        dispatch({ type:SETSECRET,secret});
    };
};

export const activePlayer = (active,loader)=> {
    return (dispatch)=> {
        dispatch({ type:ACPLAYER,active,loader});
    };
};

export const setDimensions = ()=> {
    var dimensions = { width: Platform.OS === 'web' ? innerWidth : Dimensions.get('window').width, height: Platform.OS === 'web' ? innerHeight : Dimensions.get('window').height };
    return (dispatch)=> {
        dispatch({ type:DIMENSIONS,dimensions});
    };
};

export const setUser = (user)=> {
    return (dispatch)=> {
        dispatch({ type:SETUSER,user});
    };
};

export const clientUser = (client)=> {
    return (dispatch)=> {
        dispatch({ type:SETCLIENT,client});
    };
};

export const setPlaylist = (playlist)=> {
    return (dispatch)=> {
        dispatch({ type:SETPLAYLIST,playlist});
    };
};
