import {SETPLAYLIST} from "../actions/Types"
const INTIAL_STATE=
{
  playlist:null,
}
export default (state=INTIAL_STATE,action)=>{

    switch (action.type) {
        case SETPLAYLIST:
            return {...INTIAL_STATE,playlist:action.playlist}
        default:
            return state;
    }
}