
import React ,{Component}from 'react';
import { render } from 'react-dom';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';

class  ControlBtn extends  Component{
    
    render(){
        const size =this.props.size ? this.props.size : 26;
        return (
            <View
                style={[styles.buttonHolder,this.props.transparent&&styles.transparent,
                {
                    width: size,
                    height:size,
                    borderRadius:size/2,
                    marginRight:this.props.mrR?this.props.mrR:0,
                    marginLeft:this.props.mrL?this.props.mrL:0
                }]}
            >
                <TouchableOpacity style={styles.able} activeOpacity={0.9} onPress={this.props.onPress}>
                     {this.props.children}
                </TouchableOpacity>
            </View>
        );
    };
};
const styles = StyleSheet.create({

    buttonHolder:
    {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        flexDirection: "row",
        backgroundColor:"#1C1F26"
    },
    transparent:
    {
        backgroundColor:"transparent"
    },
    able:
    {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
    },

});

export { ControlBtn }