
import Svg, { Path, G } from 'react-native-svg';
import React from 'react';
import { View, StyleSheet } from 'react-native';
const Repeat = (props) => {
    return (
        <View >
            <Svg  width="14.378" height="14.112" viewBox="0 0 14.378 14.112">

                <Path d="M36.927,279.263a.719.719,0,0,0-.719.719,2.159,2.159,0,0,1-2.157,2.157H28.3v-2.157a.719.719,0,0,0-1.168-.561L23.538,282.3a.719.719,0,0,0,0,1.123l3.594,2.876a.719.719,0,0,0,1.168-.561v-2.157h5.751a3.6,3.6,0,0,0,3.594-3.594A.719.719,0,0,0,36.927,279.263Z" transform="translate(-23.268 -272.34)" fill={props.color} />
                <Path d="M23.987,7.188a.719.719,0,0,0,.719-.719,2.159,2.159,0,0,1,2.157-2.157h5.751V6.47a.719.719,0,0,0,1.168.561l3.594-2.876a.719.719,0,0,0,0-1.123L33.782.157a.719.719,0,0,0-1.168.561V2.875H26.862A3.6,3.6,0,0,0,23.268,6.47.719.719,0,0,0,23.987,7.188Z" transform="translate(-23.268 0)" fill={props.color}/>

            </Svg>
        </View>
    );
};
export { Repeat }
const styles = StyleSheet.create({

});