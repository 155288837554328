
import React from 'react';
import { View,StyleSheet, TouchableOpacity} from 'react-native';
const Points = (props) => {
    return (
               <View style={styles.points}>
                 <View style={styles.point}></View>
                 <View style={styles.point}></View>
                 <View style={styles.point}></View>
              </View>
    );
};

const styles = StyleSheet.create({
      points:
      {
          display:'flex',
          flexDirection:"column",
          justifyContent:"space-between",
          alignItems:"center",
          width:3,
          alignSelf:"flex-end"
      },
      point:
      {
          width:6,
          height:6,
          borderRadius:3,
          backgroundColor:"#181A20",
          marginTop:2
      }
});
export { Points }