

import React, { Component } from 'react';
import { View, Platform, StyleSheet, StatusBar, Text} from 'react-native';
import RootNavigator from './rooter/RootNavigator';
import { applyMiddleware, createStore } from 'redux';
import reducers from './reducers';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { Orientation, DeviceOrientation } from './services/DeviceOrientation';
import { isMobile } from 'react-device-detect';


const myApp = () => {
  return (
    <View style={[styles.container_, { minHeight: Platform.OS === 'web' ? '100vh' : '100%', }]} >
      <StatusBar backgroundColor='transparent' translucent={true} />
      <Provider store={createStore(reducers, {}, applyMiddleware(ReduxThunk))}>
        <RootNavigator />
      </Provider>
    </View>
  )
}
class App extends Component {
  render() {
    return (
      <>
        {(Platform.OS != 'web' || !isMobile) ?
          <>
            {myApp()}
          </>
          :
          <DeviceOrientation lockOrientation={'landscape'}>

            <Orientation orientation='landscape' alwaysRender={false}>
              <View style={[styles.plzRotate, { height: '100vh', width: '100vw' }]}>
                <Text>Merci de pivoter votre appareil</Text>
              </View>
            </Orientation>
            <Orientation orientation='portrait'>
              {myApp()}
            </Orientation>
          </DeviceOrientation>
        }
      </>
    );
  }
}
const styles = StyleSheet.create({
  container_:
  {
    height: '100%',
    width: '100%',
    flex: 1,
    backgroundColor: '#170444'
  },
  plzRotate:
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});
export default App;
