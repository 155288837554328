
import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity, Image, Text, Platform, ScrollView } from 'react-native';
import { dimensions, noAvatar, resetLogin, apiUrl,passwordChecker } from "../Dev";
import styleG from "../assets/style/styleG";
import { connect } from "react-redux";
import { FlatInput, Buttons } from '../common';
import { UploadImg } from '../assets/icons'
import ImagePicker from 'react-native-image-crop-picker';
import ImageUploader from "../services/ImageUploader";
import Cropper_Cu from '../components/Cropper_Cu';
import OverLay from "../common/OverLay";
import { Check } from "../assets/icons";
import { setDimensions } from "../actions";
import loginMd from '../assets/img/loginMd.png';
import axios from 'axios';
import MessageBox from '../common/MessageBox';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listHeigh: 180,
            userPicture: noAvatar,
            cropedImg: null,
            showOverLay: false,
            sliderVal: 1,
            crop: { x: 0, y: 0 },
            lowerCase: false,
            uperCase: false,
            number_: false,
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            password: "",
            password_confirm: "",
            civility: "m",
            image: '',
            type_image: '',
            size_image: 0,
            invitation_token: '',
            showBoxMsg: false,
            boxMsg: "",
            boxColor: '#28a745',
            loadingBtn: false,
            passwordCheck:"",
        };
    }

    hideOverlay() { this.setState({ showOverLay: false }); }

    componentDidMount() {
        if (Platform.OS == "web") {
            const urlParams = new URLSearchParams(window.location.search);
            const invit = urlParams.get('invit');
            if (invit) {
                this.setState({ invitation_token: invit });
            } else {
                this.props.navigation.dispatch(resetLogin);
            }
        }
    }

    saveNewUser() {
        let formData = new FormData();
        formData.append('invitation_token', this.state.invitation_token);
        formData.append('first_name', this.state.first_name);
        formData.append('last_name', this.state.last_name);
        formData.append('email', this.state.email);
        formData.append('phone', this.state.phone);
        formData.append('password', this.state.password);
        formData.append('password_confirm', this.state.password_confirm);
        formData.append('civility', this.state.civility);
        formData.append('image', this.state.image);
        formData.append('type_image', this.state.type_image);
        formData.append('size_image', parseInt(this.state.size_image/ (1024 * 1024)));
        this.setState({ loadingBtn: true });

        axios.post(apiUrl + 'inscription', formData, { headers: { "Access-Control-Allow-Origin": "*" } })
            .then(response => {

                if (response.data.status == 'error') {
                    this.setState({ boxColor: '#dc3545', boxMsg: response.data.messages.join('\n') }, () => {
                        this.setState({ showBoxMsg: true });
                    });
                    this.setState({ loadingBtn: false });
                }
                else if (response.data.status == 'success') {
                    this.setState({ boxColor: '#28a745', boxMsg: response.data.messages.join('\n') }, () => {
                        this.setState({ showBoxMsg: true });
                    });
                    this.setState({ loadingBtn: false });
                }
            }).catch((error) => {
                if (error.message == 'Network Error') {
                    this.setState({ boxColor: '#dc3545', boxMsg: 'Erreur réseau' }, () => {
                        this.setState({ showBoxMsg: true });
                    })
                }
                this.setState({ loadingBtn: false });
            });
    }

    uploadImg() {
        if (Platform.OS != "web") {
            ImagePicker.openPicker({
                width: 500,
                height: 500,
                cropping: true,
                includeBase64: true
            }).then(image => {
                var size_ = parseInt((image.size / (1024 * 1024))), type_ = image.mime.split().length > 1 ? image.mime.split()[1] : '';
                this.setState({ cropedImg: "data:" + image.mime + ";base64," + image.data, image: image.data, size_image: size_, type_image: type_ })
            });
        }
    }
   
    onDrop(pictureFiles, pictureDataURLs) {

        if (pictureDataURLs.length) {
            this.setState({ userPicture: pictureDataURLs[pictureDataURLs.length - 1], size_image: pictureFiles[pictureFiles.length - 1].size, type_image: 'png' },
                () => {
                    if (this.state.userPicture) {
                        this.setState({ showOverLay: true })
                    }
                }
            )
        }
    }
    render() {
        return (
            <>
                <MessageBox
                    bg={this.state.boxColor} msg={this.state.boxMsg} show={this.state.showBoxMsg} closeBox={() => {
                        this.setState({ showBoxMsg: false });
                    }}
                />
                <ScrollView style={[styleG.container_,
                {
                    paddingTop: 70,
                    maxHeight: Platform.OS == 'web' ? "100vh" : dimensions.height,
                }]}>

                    <View
                        onLayout={() => {
                            if (Platform.OS == 'web') {
                                this.props.setDimensions();
                            }

                        }}
                        style={{ display: 'flex', alignItems: "center", width: "100%" }}>
                        <View style={styles.viewForm}>
                            <View style={[styles.form_, this.props.dimensions.width < 751 && styles.form_radius, {
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10,
                                width: this.props.dimensions.width > 750 ? "50%" : "100%",
                            }]}>

                                <View style={styles.user}>
                                    <View style={styles.Avatar}>
                                        <Image
                                            source={{ uri: this.state.cropedImg ? this.state.cropedImg : noAvatar }}
                                            style={styles.img}
                                        />
                                    </View>
                                    <View style={styles.selectAvatar}>
                                        {Platform.OS != "web" ? <TouchableOpacity activeOpacity={9} onPress={() => this.uploadImg()} style={styles.selectAvatarBtn}>

                                        </TouchableOpacity> :
                                            <ImageUploader
                                                withIcon={false}
                                                buttonText=''
                                                withLabel={false}
                                                singleImage={false}
                                                onChange={(pictureFiles, pictureDataURLs) => this.onDrop(pictureFiles, pictureDataURLs)}
                                                imgExtension={['.jpg', '.png', '.jpeg']}
                                                maxFileSize={5242880}
                                                buttonStyles={{
                                                    position: 'absolute',
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: 0,
                                                    top: 0,
                                                    left: 0,
                                                    margin: 0,
                                                    backgroundColor: "transparent"
                                                }}
                                                withPreview={false}
                                                label=''
                                                errorStyle={{ display: "none" }}
                                                className="uploadImg"
                                            />
                                        }
                                        <UploadImg size={21} color={'#525252'} />
                                    </View>
                                </View>
                                <FlatInput
                                    placeholder='Nom'
                                    secureTextEntry={false}
                                    onChangeText={(first_name) => {
                                        this.setState({ first_name })
                                    }}
                                    value={this.state.first_name}
                                    onSubmitEditing={() => { }}
                                    containerStyle={{ borderBottomColor: '#BEBEBE' }}
                                    placeholderTextColor={'#989898'}
                                    style={{ color: "#181A20" }}
                                />
                                <FlatInput
                                    placeholder='prénom'
                                    secureTextEntry={false}
                                    onChangeText={(last_name) => {
                                        this.setState({ last_name })
                                    }}
                                    value={this.state.last_name}
                                    onSubmitEditing={() => { }}
                                    containerStyle={{ borderBottomColor: '#BEBEBE' }}
                                    placeholderTextColor={'#989898'}
                                    style={{ color: "#181A20" }}
                                />
                                <FlatInput
                                    placeholder='Email'
                                    secureTextEntry={false}
                                    onChangeText={(email) => {
                                        this.setState({ email })
                                    }}
                                    value={this.state.email}
                                    onSubmitEditing={() => { }}
                                    containerStyle={{ borderBottomColor: '#BEBEBE' }}
                                    placeholderTextColor={'#989898'}
                                    style={{ color: "#181A20" }}
                                />
                                <FlatInput
                                    placeholder='Téléphone'
                                    secureTextEntry={false}
                                    onChangeText={(phone) => {
                                        this.setState({ phone })
                                    }}
                                    value={this.state.phone}
                                    onSubmitEditing={() => { }}
                                    containerStyle={{ borderBottomColor: '#BEBEBE' }}
                                    placeholderTextColor={'#989898'}
                                    style={{ color: "#181A20" }}
                                />
                                <FlatInput
                                    placeholder='Mot de passe'
                                    secureTextEntry={true}
                                    onChangeText={(password) => {
                                        this.setState({ password },function(){
                                            this.setState({passwordCheck:passwordChecker(this.state.password)});
                                        })
                                    }}
                                    value={this.state.password}
                                    onSubmitEditing={() => { }}
                                    containerStyle={{ borderBottomColor: '#BEBEBE' }}
                                    placeholderTextColor={'#989898'}
                                    style={{ color: "#181A20" }}
                                />
                                <FlatInput
                                    placeholder='Confirmez votre mot de passe'
                                    secureTextEntry={true}
                                    onChangeText={(password_confirm) => {
                                        this.setState({ password_confirm })
                                    }}
                                    value={this.state.password_confirm}
                                    onSubmitEditing={() => { }}
                                    containerStyle={{ borderBottomColor: '#BEBEBE' }}
                                    placeholderTextColor={'#989898'}
                                    style={{ color: "#181A20" }}
                                />
                                <View style={styles.passwordPower}>
                                    <View style={styles.passwordPowerStep}>
                                        <View style={styles.stepIcon}>
                                            <Check
                                                times={!this.state.passwordCheck.includes('1')}
                                                color={!this.state.passwordCheck.includes('1') ? '#a0a0a0' : "#28a745"} size={20} />
                                        </View>
                                        <Text style={[styles.stepLabel, { color: !this.state.passwordCheck.includes('1') ? '#a0a0a0' : "#28a745" }]}>
                                        Contient une lettre minuscule
                                   </Text>
                                    </View>
                                    <View style={styles.passwordPowerStep}>
                                        <View style={styles.stepIcon}>
                                            <Check
                                                times={!this.state.passwordCheck.includes('2')}
                                                color={!this.state.passwordCheck.includes('2') ? '#a0a0a0' : "#28a745"} size={20} />
                                        </View>
                                        <Text style={[styles.stepLabel, { color: !this.state.passwordCheck.includes('2') ? '#a0a0a0' : "#28a745" }]}>
                                          Contient une lettre majuscule
                                   </Text>
                                    </View>
                                    <View style={styles.passwordPowerStep}>
                                        <View style={styles.stepIcon}>
                                            <Check
                                                times={!this.state.passwordCheck.includes('3')}
                                                color={!this.state.passwordCheck.includes('3') ? '#a0a0a0' : "#28a745"} size={20} />
                                        </View>
                                        <Text style={[styles.stepLabel, { color: !this.state.passwordCheck.includes('3') ? '#a0a0a0' : "#28a745" }]}>
                                           Contient un numéro
                                   </Text>
                                    </View>
                                    <View style={styles.passwordPowerStep}>
                                        <View style={styles.stepIcon}>
                                            <Check
                                                times={!this.state.passwordCheck.includes('4')}
                                                color={!this.state.passwordCheck.includes('4') ? '#a0a0a0' : "#28a745"} size={20} />
                                        </View>
                                        <Text style={[styles.stepLabel, { color: !this.state.passwordCheck.includes('4') ? '#a0a0a0' : "#28a745" }]}>
                                        Contient un caractère spécial
                                   </Text>
                                    </View>
                                    <View style={styles.passwordPowerStep}>
                                        <View style={styles.stepIcon}>
                                            <Check
                                                times={!this.state.passwordCheck.includes('5')}
                                                color={!this.state.passwordCheck.includes('5') ? '#a0a0a0' : "#28a745"} size={20} />
                                        </View>
                                        <Text style={[styles.stepLabel, { color: !this.state.passwordCheck.includes('5') ? '#a0a0a0' : "#28a745" }]}>
                                            Contient 8 caractères
                                   </Text>
                                    </View>

                                </View>
                                <View style={{ marginTop: 20 }}>
                                    <Buttons disabled={this.state.disabledLogin} loading={this.state.loadingBtn} onPress={() => { this.saveNewUser();}} text={'CONFIRMER'} />
                                </View>
                            </View>
                            {this.props.dimensions.width < 750 ? null : <View style={styles.imgView}>
                                <Image style={styles.imgBg} source={{ uri: loginMd }} />
                            </View>}
                        </View>
                    </View>
                    <View style={{ width: "100%", height: 60 }}></View>
                </ScrollView>
                { Platform.OS == "web" && <OverLay centerContent isVisible={this.state.showOverLay} onOverlayPress={() => { this.hideOverlay(); }}>
                    <Cropper_Cu
                        image={this.state.userPicture}
                        cancelpress={() => this.hideOverlay()}
                        setUrl={(img) => {
                            this.setState({ cropedImg: img, image: (img.split(',').length > 1 ? img.split(',')[1] : '') });
                            this.hideOverlay();
                        }
                        }
                    />
                </OverLay>}
            </>
        );
    }
};

const styles = StyleSheet.create({
    user:
    {
        alignSelf: 'center',
        marginBottom: 30
    },
    Avatar:
    {
        width: 130,
        height: 130,
        borderRadius: 65,
        overflow: 'hidden',
        marginTop: 30,
        borderColor: "#F4F4F4",
        borderWidth: 4
    },
    selectAvatar:
    {
        position: 'absolute',
        zIndex: 10,
        width: 46,
        height: 46,
        borderRadius: 23,
        backgroundColor: "#F4F4F4",
        bottom: -5,
        left: -5,
        borderWidth: 2,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        borderColor: "#fff"
    },
    selectAvatarBtn:
    {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 10,
    },
    img:
    {
        width: "100%",
        height: "100%",
        resizeMode: "cover"
    },
    contact: {
        paddingBottom: 7,
        paddingTop: 10,
        borderColor: "#DE1F41",
        borderBottomWidth: 1,
        color: "#fff",
        fontSize: 14,
        fontWeight: "500",
        alignSelf: "center"
    },
    noBorder:
    {
        color: "#C6C2C2",
        fontWeight: "normal",
        borderBottomWidth: 0,
    },

    passwordPower:
    {
        backgroundColor: "#ffffff26",
        display: "flex",
        flexDirection: "column",
        padding: 15,
        borderRadius: 10,
        marginTop: 20,
        marginBottom: 10
    },
    passwordPowerStep:
    {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    stepIcon:
    {
        marginRight: 10
    },
    viewForm:
    {
        maxWidth: 1100,
        width: '90%',
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    form_: {
        backgroundColor: "#fff",
        padding: 20,
        overflow: "hidden",
    },
    form_radius:
    {
        borderRadius: 10
    },
    imgView: {
        width: "50%",
        height: "100%",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        overflow: 'hidden'
    },
    imgBg:
    {
        width: '100%',
        height: '100%',
        resizeMode: "cover"
    }
});
const mapStateToProps = state => {
    return {
        dimensions: state.dimensions.dimensions,
    }
}
export default connect(mapStateToProps, { setDimensions })(Register);