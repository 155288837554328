import React, {Component} from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  Platform,
  Animated,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import {slidePanel, noAvatar, apiUrl} from '../Dev';
import styleG from '../assets/style/styleG';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Cropper_Cu from '../components/Cropper_Cu';
import {connect} from 'react-redux';
import {FlatInput, Buttons} from '../common';
import OverLay from '../common/OverLay';
import ImagePicker from 'react-native-image-crop-picker';
import ImageUploader from '../services/ImageUploader';
import {UploadImg} from '../assets/icons';
import {setUser} from '../actions';
import axios from 'axios';
import MessageBox from '../common/MessageBox';
import {UserIcon} from '../assets/icons';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listHeigh: 180,
      toggleOverLay: '',
      galleryAnimation: new Animated.Value(0),
      userPicture: null,
      loadingBtnChange: false,
      new_password: '',
      old_password: '',
      confirm_password: '',
      showBoxMsg: false,
      boxMsg: '',
      boxColor: '#28a745',
      imgLoader: false,
      imgSize: 0,
    };
  }
  componentDidMount() {}
  fadeMe(state_, toVal, duration_) {
    Animated.timing(state_, {
      toValue: toVal,
      duration: duration_,
      useNativeDriver: false,
    }).start(() => {});
  }
  uploadImg(from_ = 'files') {
    let options = {
      width: 500,
      height: 500,
      cropping: true,
      includeBase64: true,
    };
    if (Platform.OS != 'web') {
      if (from_ == 'camera') {
        ImagePicker.openCamera(options).then(image => {
          var size_ = parseInt(image.size / (1024 * 1024));
          var type_ =
            image.mime.split().length > 1 ? image.mime.split()[1] : '';
          this.saveAvatar(image.data, type_, size_);
        });
      } else {
        ImagePicker.openPicker(options).then(image => {
          var size_ = parseInt(image.size / (1024 * 1024));
          var type_ =
            image.mime.split('/').length > 1 ? image.mime.split('/')[1] : '';
          this.saveAvatar(image.data, type_, size_);
        });
      }
    }
  }
  saveAvatar(data, type, size) {
    this.hideOptions();
    if (this.props.secret) {
      this.setState({imgLoader: true});
      let formData = new FormData();

      formData.append('image', data);
      formData.append('type_image', type);
      formData.append('size_image', size);

      axios
        .post(apiUrl + 'change-avatar', formData, {
          headers: {Authorization: 'Bearer ' + this.props.secret.access_token},
        })
        .then(response => {
          if (response.data.status == 'error') {
            this.setState(
              {boxColor: '#dc3545', boxMsg: response.data.messages.join('\n')},
              () => {
                this.setState({showBoxMsg: true});
              },
            );
            this.setState({imgLoader: false});
          } else if (response.data.status == 'success') {
            this.setState(
              {boxColor: '#28a745', boxMsg: response.data.messages.join('\n')},
              () => {
                this.setState({showBoxMsg: true});
              },
            );
            this.props.setUser({
              civility: this.props.user.civility,
              first_name: this.props.user.civility,
              last_name: this.props.user.last_name,
              email: this.props.user.email,
              phone: this.props.user.phone,
              avatar: 'data:image/' + type + ';base64,' + data,
            });
            this.setState({imgLoader: false});
          }
        })
        .catch(error => {
          if (error.message == 'Network Error') {
            this.setState(
              {boxColor: '#dc3545', boxMsg: 'Erreur réseau'},
              () => {
                this.setState({showBoxMsg: true});
              },
            );
          }
          this.setState({imgLoader: false});
        });
    }
  }

  changePass() {
    if (this.props.secret) {
      let formData = new FormData();
      this.setState({loadingBtnChange: true});
      formData.append('old_password', this.state.old_password);
      formData.append('new_password', this.state.new_password);
      formData.append('confirm_password', this.state.confirm_password);

      axios
        .post(apiUrl + 'change-password', formData, {
          headers: {Authorization: 'Bearer ' + this.props.secret.access_token},
        })
        .then(response => {
          if (response.data.status == 'error') {
            this.setState(
              {boxColor: '#dc3545', boxMsg: response.data.messages.join('\n')},
              () => {
                this.setState({showBoxMsg: true});
              },
            );
            this.setState({loadingBtnChange: false});
          } else if (response.data.status == 'success') {
            this.setState(
              {boxColor: '#28a745', boxMsg: response.data.messages.join('\n')},
              () => {
                this.setState({showBoxMsg: true});
              },
            );
            this.setState({loadingBtnChange: false});
          }
        })
        .catch(error => {
          if (error.message == 'Network Error') {
            this.setState(
              {boxColor: '#dc3545', boxMsg: 'Erreur réseau'},
              () => {
                this.setState({showBoxMsg: true});
              },
            );
          }
          this.setState({loadingBtnChange: false});
        });
    }
  }

  toggleOverlay(id = '') {
    this.setState({toggleOverLay: id});
  }
  hideOptions(web = false) {
    !web
      ? slidePanel(this.state.galleryAnimation, 0, 500, this.toggleOverlay())
      : this.toggleOverlay();
  }

  onDrop(pictureFiles, pictureDataURLs) {
    if (pictureDataURLs.length && pictureFiles.length) {
      this.setState(
        {
          userPicture: pictureDataURLs[pictureDataURLs.length - 1],
          imgSize: pictureFiles[pictureFiles.length - 1].size,
        },
        () => {
          if (this.state.userPicture) {
            this.toggleOverlay('webPicker');
          }
        },
      );
    }
  }
  render() {
    return (
      <>
        <MessageBox
          bg={this.state.boxColor}
          msg={this.state.boxMsg}
          show={this.state.showBoxMsg}
          closeBox={() => {
            this.setState({showBoxMsg: false});
          }}
        />
        {Platform.OS != 'web' ? (
          <OverLay
            isVisible={this.state.toggleOverLay == 'picker' ? true : false}
            onOverlayPress={() => {
              this.hideOptions();
            }}>
            <Animated.View
              style={[styles.imgFrom, {width: this.state.galleryAnimation}]}>
              <View style={[styles.centerContent]}>
                <View style={[styles.circleBtn]}>
                  <UploadImg size={22} color="#fff" />
                </View>
                <Text>Camera</Text>
                <TouchableOpacity
                  activeOpacity={9}
                  onPress={() => {
                    this.uploadImg('camera');
                  }}
                  style={styles.loadNewAvater}></TouchableOpacity>
              </View>
              <View style={[styles.centerContent]}>
                <View style={[styles.circleBtn]}>
                  <UploadImg gallery size={22} color="#fff" />
                </View>
                <Text>Galerie</Text>
                <TouchableOpacity
                  activeOpacity={9}
                  onPress={() => {
                    this.uploadImg();
                  }}
                  style={styles.loadNewAvater}></TouchableOpacity>
              </View>
            </Animated.View>
          </OverLay>
        ) : (
          <OverLay
            centerContent
            isVisible={this.state.toggleOverLay == 'webPicker' ? true : false}
            onOverlayPress={() => {
              this.hideOptions(true);
            }}>
            <Cropper_Cu
              image={this.state.userPicture}
              cancelpress={() => this.hideOptions(true)}
              setUrl={img => {
                var size_ = parseInt(this.state.imgSize / (1024 * 1024));
                var type_ = '';
                this.saveAvatar(
                  img.split(',').length > 1 ? img.split(',')[1] : '',
                  type_,
                  size_,
                );
                this.hideOptions(true);
              }}
            />
          </OverLay>
        )}
        <View style={styleG.container_}>
          <Header navigation={this.props.navigation} />
          <ScrollView style={[{flex: 1}]}>
            <View>
              <View style={styles.user}>
                <View style={[styleG.screenheader, styles.pageTitle]}>
                  <UserIcon size={20} color={'#1E202C'} />
                  <Text style={styleG.screenheaderText}>
                    Informations personnelles
                  </Text>
                </View>
                <View style={styles.Avatar}>
                  <Image
                    source={{
                      uri: this.props.user
                        ? this.props.user.avatar
                          ? this.props.user.avatar
                          : noAvatar
                        : noAvatar,
                    }}
                    style={styles.img}
                  />
                  {Platform.OS != 'web' ? (
                    <TouchableOpacity
                      activeOpacity={9}
                      onPress={() => {
                        this.toggleOverlay('picker');
                        slidePanel(this.state.galleryAnimation, 160, 500);
                      }}
                      style={styles.loadNewAvater}></TouchableOpacity>
                  ) : (
                    <ImageUploader
                      withIcon={false}
                      buttonText=""
                      withLabel={false}
                      singleImage={false}
                      onChange={(pictureFiles, pictureDataURLs) =>
                        this.onDrop(pictureFiles, pictureDataURLs)
                      }
                      imgExtension={['.jpg', '.png', '.jpeg']}
                      maxFileSize={5242880}
                      buttonStyles={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        borderRadius: 0,
                        top: 0,
                        left: 0,
                        margin: 0,
                        backgroundColor: 'transparent',
                      }}
                      withPreview={false}
                      label=""
                      errorStyle={{display: 'none'}}
                      className="uploadImg"
                    />
                  )}
                  {this.state.imgLoader && (
                    <View style={styles.imgLoader}>
                      <ActivityIndicator size="small" color="#DE1F41" />
                    </View>
                  )}
                </View>
                <View style={styles.userInfo}>
                  <Text style={styles.contact}>
                    {this.props.user
                      ? this.props.user.first_name +
                        ' ' +
                        this.props.user.last_name
                      : ''}
                  </Text>
                  <Text
                    style={[
                      styles.contact,
                      styles.noBorder,
                      {color: '#4F5672'},
                    ]}>
                    {this.props.user ? this.props.user.email : ''}
                  </Text>
                  <Text
                    style={[
                      styles.contact,
                      styles.noBorder,
                      {paddingTop: 0, color: '#4F5672'},
                    ]}>
                    {this.props.user ? this.props.user.phone : ''}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: 30,
                }} >
                <View
                  style={[
                    {
                      width: this.props.dimensions.width - 40,
                    },
                    styles.passWordForm,
                    Platform.OS == 'web' && styles.passWordFormWeb,
                  ]}>
                  <View style={[styleG.screenheader]}>
                    <Text style={[styleG.screenheaderText,{marginLeft:0}]}>
                      Réinitialisez votre mot de passe
                    </Text>
                  </View>
                  <FlatInput
                    placeholder="Mot de passe actuel"
                    secureTextEntry={true}
                    value={this.state.old_password}
                    onChangeText={old_password => {
                      this.setState({old_password});
                    }}
                    onSubmitEditing={() => {}}
                  />
                  <FlatInput
                    placeholder="Nouveau mot de passe"
                    secureTextEntry={true}
                    value={this.state.new_password}
                    onChangeText={new_password => {
                      this.setState({new_password});
                    }}
                    onSubmitEditing={() => {}}
                  />
                  <FlatInput
                    placeholder="Confirmez votre mot de passe"
                    secureTextEntry={true}
                    value={this.state.confirm_password}
                    onChangeText={confirm_password => {
                      this.setState({confirm_password});
                    }}
                    onSubmitEditing={() => {}}
                  />
                  <View style={{marginTop: 20}}>
                    <Buttons
                      loading={this.state.loadingBtnChange}
                      onPress={() => {
                        this.changePass();
                      }}
                      text={'CONFIRMER'}
                    />
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
          <Footer
            activeOption={1}
            navigation={this.props.navigation}
            active={4}
          />
        </View>
      </>
    );
  }
}
const styles = StyleSheet.create({
  user: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  Avatar: {
    width: 130,
    height: 130,
    borderRadius: 65,
    overflow: 'hidden',
    marginTop: 25,
  },
  img: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    zIndex: 1,
  },
  loadNewAvater: {
    width: '100%',
    height: '100%',
    zIndex: 99,
    top: 0,
    left: 0,
    position: 'absolute',
  },
  contact: {
    paddingBottom: 7,
    paddingTop: 7,
    borderColor: '#DE1F41',
    borderBottomWidth: 1,
    color: '#1E202C',
    fontSize: 14,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  noBorder: {
    color: '#C6C2C2',
    fontWeight: 'normal',
    borderBottomWidth: 0,
  },
  passWordForm: {
    marginTop: 20,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  passWordFormWeb: {
    maxWidth: 700,
  },
  imgFrom: {
    display: 'flex',
    backgroundColor: '#fff',
    position: 'absolute',
    zIndex: 20,
    top: 150,
    left: 0,
    flexDirection: 'row',
    overflow: 'hidden',
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
  },
  circleBtn: {
    backgroundColor: '#DE1F41',
    height: 40,
    width: 40,
    borderRadius: 21,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: 20,
  },
  imgLoader: {
    width: 130,
    height: 130,
    position: 'absolute',
    zIndex: 100,
    backgroundColor: '#00000052',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageTitle: {
    width: '100%',
    maxWidth: 700,
    paddingLeft: Platform.OS=='web'?20:30,
    paddingRight: 20,
  },
});
const mapStateToProps = state => {
  return {
    user: state.user.user,
    dimensions: state.dimensions.dimensions,
    secret: state.secret.secret,
  };
};
export default connect(mapStateToProps, { setUser})(Profile);
