import React, {Component} from 'react';
import {
  View,
  Text,
  StyleSheet,
  Platform,
  Image,
  ActivityIndicator,
  BackHandler,
  Alert 
} from 'react-native';
import styleG from '../assets/style/styleG';
import {ControlBtn} from '../common';
import {Play, Switch} from '../assets/icons';
import Slider_ from '../common/Slider_';
import {NoConnection} from '../components';
import {Mic, Live, Bars} from '../assets/icons';
import {connect} from 'react-redux';
import TrackPlayer, {State} from '../services/TrackPlayer';
import {setTrackState, activePlayer, setLiveTrack,setCurrentTrack} from '../actions';
import {formatDuration, switchTrack} from '../Dev';
import Footer from '../components/Footer';
import Header from '../components/Header';
import axios from 'axios';
import MessageBox from '../common/MessageBox';
import {TouchableOpacity} from 'react-native-gesture-handler';


let timeUpdated = true;
let timeInterval = null;
class GPlayer extends Component {
  constructor() {
    super();
    this.state = {
      playerStateEvent: null,
      playerChangeEvent: null,
      positionTime: 0,
      title: ' ',
      artist: ' ',
      artwork: null,
      end_at: '',
      showBoxMsg: false,
      boxMsg: '',
      boxColor: '#28a745',
      noConnection: false,
    };
  }

  componentDidMount() {
    if (!this.state.playerStateEvent) {
      this.registerEvents();
    }
    this.getLive();
    /* BackHandler.addEventListener('hardwareBackPress',()=> {
      this.props.navigation.goBack();
      return true;
  }); */
  }
  componentDidUpdate() {
    if (this.state.noConnection && this.props.currentTrack.id != -2) {
      this.setState({noConnection: false});
    } else if (
      !this.state.noConnection &&
      this.props.currentTrack.id == -2 &&
      !this.props.liveTrack
    ) {
      this.setState({noConnection: true});
    }
  }
  getLive() {
    var liveTrack;
    var obj_;
    if (this.props.client) {
      if (this.props.client.info_json) {
        axios
          .get(this.props.client.info_json, [], {
            headers: {'Access-Control-Allow-Origin': '*'},
          })
          .then(response => {
            if (Array.isArray(response.data)) obj_ = response.data[0];
            else obj_ = response.data;
            this.setState(
              {
                title: obj_.title,
                artist: obj_.artist,
                artwork: obj_.cover,
                end_at: obj_.end_at,
              },
              () => {
                if (
                  this.state.title != obj_.title ||
                  this.state.artist != obj_.artist ||
                  this.state.artwork != obj_.artwork
                ) {
                  liveTrack = {
                    id: -2,
                    url: this.props.client.url_live_default,
                    title: obj_.title,
                    artist: obj_.artist,
                    artwork: obj_.cover,
                    duration: 1,
                    isLive: true,
                    started_at: obj_.started_at,
                    end_at: obj_.end_at,
                  };
                  this.props.setLiveTrack(liveTrack);
                }
                var d2 = new Date(this.state.end_at.replace('+', '.'));
                let interval = setInterval(async () => {
                  var dNow = new Date();
                  dNow.setHours(dNow.getHours() - 1);
                  if ( this.props.currentTrack.id != -2 || !this.props.trackState )
                    clearInterval(interval);
                  if (dNow > d2) {
                    if (this.props.currentTrack.id == -2) this.getLive();
                    clearInterval(interval);
                  }
                }, 1000);
              },
            );
            this.setState({noConnection: false});
          })
          .catch(error => {
            if (error.response.status == 404 || error.response.status == 400)
              this.setState({noConnection: true});
          });
      } else {
        this.setState({noConnection: true});
      }
    }
  }

  getPos() {
    clearInterval(timeInterval);
    timeInterval = setInterval(async () => {
      let position = 0;
      if (timeUpdated) {
        timeUpdated = false;
        position = await TrackPlayer.getPosition();
        this.setState({positionTime: position}, function () {
          timeUpdated = true;
          if (!this.props.trackState || this.props.currentTrack.id == -2)
            clearInterval(timeInterval);
        });
      }
    }, 1000);
  }

  async playTrack() {
    if (!this.props.trackState) {
      this.setState({palyLoader: true});
    }
    if (Platform.OS == 'web') {
      if (!TrackPlayer.isUrlSet()) {
        if (this.props.liveTrack) switchTrack('', [this.props.liveTrack]);
        else {
          this.setState(
            {
              palyLoader: false,
              boxColor: '#dc3545',
              boxMsg:
                'Service momentanément indisponible. Veuillez réessayer plus tard',
            },
            () => {
              this.setState({showBoxMsg: true});
            },
          );
        }
      } else {
        if (TrackPlayer.isPlay()) {
          TrackPlayer.pause();
        } else {
          TrackPlayer.play(
            this.props.currentTrack.id != -2 ? '' : this.props.currentTrack.url,
          );
        }
      }
    } else {
      let state_ = false;
      let queue = await TrackPlayer.getQueue();
      if (queue.length == 0) {
        if (this.props.liveTrack) switchTrack('', [this.props.liveTrack]);
        else {
          this.setState(
            {
              palyLoader: false,
              boxColor: '#dc3545',
              boxMsg:
                'Service momentanément indisponible. Veuillez réessayer plus tard',
            },
            () => {
              this.setState({showBoxMsg: true});
            },
          );
        }
      } else {
        const state = await TrackPlayer.getState();
        if (state === State.Playing) state_ = true;

        if (state_) {
          if (this.props.currentTrack.id == -2) {
            await TrackPlayer.stop();
          } else {
            TrackPlayer.pause();
          }
        } else {
          TrackPlayer.play();
        }
      }
    }
  }

  async setCurrent(status_, webSwitch = '') {
    var currentTrack = null;
    let trackId = null,track =null,pos = -1;;
    if (status_) {
      if (Platform.OS != 'web') {
        pos = await TrackPlayer.getCurrentTrack();
        if(pos >-1)
        {
          let queue = await TrackPlayer.getQueue();
          trackId = queue[pos].id;
        }
      } else {
        trackId = webSwitch != '' ? webSwitch : this.props.currentTrack.id;
        pos = this.props.tracks.findIndex(el => el.id === trackId);
      }
      if (pos > -1) {
        track = trackId == -2 ? this.props.liveTrack : this.props.tracks[pos];
        if (track) {
          currentTrack = {
            id: track.id,
            url: track.url,
            title: track.title,
            artist: track.artist,
            artwork: track.artwork,
            duration: track.duration,
            started_at: track.started_at,
            end_at: track.end_at,
            isLive: track.id == -2 ? true : false,
          };
          this.props.setCurrentTrack(currentTrack);
        }
      }
    }
    this.props.setTrackState(status_);
    if (status_) {
      this.getLive();
      this.getPos();
      this.props.activePlayer(this.props.currentTrack.id == -2 ? 2 : 1, false);
      this.setState({palyLoader: false});
    }
  }
  registerEvents() {
    if (Platform.OS != 'web') {
      this.state.playerStateEvent = TrackPlayer.addEventListener(
        'playback-state',
        async () => {
          const playerstate = await TrackPlayer.getState();
          State.Playing === playerstate
            ? this.setCurrent(true)
            : this.setCurrent(false);
        },
      );
      this.state.playerChangeEvent = TrackPlayer.addEventListener(
        'playback-track-changed',
        async () => {
          const playerstate = await TrackPlayer.getState();
          playerstate === State.Playing
            ? this.setCurrent(true)
            : this.setCurrent(false);
        },
      );
    } else {
      TrackPlayer.addEvent_('play', () => {
        this.setCurrent(true);
      });
      TrackPlayer.addEvent_('pause', () => {
        this.setCurrent(false);
      });
      TrackPlayer.addEvent_('ended', () => {
        this.goTotrack();
      });
    }
  }

  async goTotrack(where_ = 'next') {
    let nextId = '';
    if (!this.props.currentTrack.isLive) {
      const track = this.props.tracks.findIndex(
        element => element.id == this.props.currentTrack.id,
      );
      if (track > -1) {
        if (Platform.OS != 'web') {
          switch (where_) {
            case 'prev':
              track >= 1 && TrackPlayer.skipToPrevious();
              break;
            case 'suffle':
              if (this.props.tracks.length) {
                TrackPlayer.skip(this.props.tracks[0].id);
              }
              break;
            case 'repeat':
              TrackPlayer.seekTo(0);
              break;
            default:
              track != this.props.tracks.length - 1 && TrackPlayer.skipToNext();
              break;
          }
        } else {
          switch (where_) {
            case 'prev':
              if (track >= 1) {
                TrackPlayer.play(this.props.tracks[track - 1].url);
                nextId = this.props.tracks[track - 1].id;
              }
              break;
            default:
              if (track != this.props.tracks.length - 1) {
                await TrackPlayer.play(this.props.tracks[track + 1].url);
                nextId = this.props.tracks[track + 1].id;
              }
              break;
          }
        }
        this.setCurrent(this.props.trackState, nextId);
      }
    }
  }

  render() {
    return (
      <>
        <MessageBox
          bg={this.state.boxColor}
          msg={this.state.boxMsg}
          show={this.state.showBoxMsg}
          closeBox={() => {
            this.setState({showBoxMsg: false});
          }}
        />
        <View style={[styleG.container_]}>
          <Header
            back={!this.props.currentTrack.isLive}
            goTo={() => {
              this.props.navigation.navigate('Podcasts');
            }}
            navigation={this.props.navigation}
          />
          {!this.state.noConnection ? (
            !this.props.PLoader ? (
              <View style={styles.flexBody}>
                <View style={styles.trackInfo}>
                  <View
                    style={[
                      styles.coverView,
                      this.props.dimensions.height < 600 && styles.coverViewSm,
                    ]}>
                    <Image
                      style={styles.cover}
                      source={{
                        uri: !this.props.currentTrack.isLive
                          ? this.props.currentTrack.artwork
                          : this.state.artwork,
                      }}
                    />
                    <View style={styles.coverBG}>
                      {this.props.currentTrack.isLive ? (
                        <Live hei={90} wid={60} />
                      ) : (
                        <Mic hei={75} wid={50} />
                      )}
                    </View>
                  </View>
                  <View style={styles.infos}>
                    <Text
                      style={[
                        styles.trackTitle,
                        this.props.dimensions.height < 600 &&
                          styles.trackTitleSm,
                      ]}
                      numberOfLines={2}>
                      {!this.props.currentTrack.isLive
                        ? this.props.currentTrack.title
                        : this.state.title}
                    </Text>
                    <Text style={styles.name}>
                      {!this.props.currentTrack.isLive
                        ? this.props.currentTrack.artist
                        : this.state.artist}
                    </Text>
                  </View>
                  {/*  <Volume/> */}
                </View>
                <View style={styles.slider}>
                  {!this.props.currentTrack.isLive && (
                    <Slider_
                      valueChange={() => {}}
                      duration={parseInt(this.props.currentTrack.duration)}
                      value={parseInt(this.state.positionTime)}
                      minLabel={formatDuration(this.state.positionTime)}
                      maxLabel={formatDuration(
                        this.props.currentTrack.duration,
                      )}
                    />
                  )}
                </View>
                <View style={styles.controls}>
                  {/*  <ControlBtn size={45} transparent mrR={20} onPress={() => { this.goTotrack('suffle'); }}>
                            <Shuffle size={34} color={this.props.currentTrack.isLive ? '#454545' : '#fff'} />
                        </ControlBtn> */}
                  <ControlBtn
                    size={45}
                    transparent
                    mrR={20}
                    onPress={() => {
                      this.goTotrack('prev');
                    }}>
                    <Switch
                      prev
                      size={34}
                      color={
                        this.props.currentTrack.isLive ? '#CCCCCC' : '#1E202C'
                      }
                    />
                  </ControlBtn>
                  {!this.state.palyLoader ? (
                    <ControlBtn
                      size={45}
                      onPress={() => {
                        this.playTrack();
                      }}>
                      <Play
                        isLive={this.props.currentTrack.isLive}
                        size={Platform.OS === 'web' ? 30 : 26}
                        pause={this.props.trackState}
                        color={'#fff'}
                      />
                    </ControlBtn>
                  ) : (
                    <ActivityIndicator size="small" color="#DE1F41" />
                  )}
                  <ControlBtn
                    size={45}
                    transparent
                    mrL={20}
                    onPress={() => {
                      this.goTotrack();
                    }}>
                    <Switch
                      size={34}
                      color={
                        this.props.currentTrack.isLive ? '#CCCCCC' : '#1E202C'
                      }
                    />
                  </ControlBtn>
                  {/*   <ControlBtn size={45} transparent mrL={20} onPress={() => { this.goTotrack('repeat'); }}>
                            <Repeat size={34} color={this.props.currentTrack.isLive ? '#454545' : '#fff'} />
                        </ControlBtn> */}
                  {!this.props.currentTrack.isLive && (
                    <View style={styles.arrow}>
                      <TouchableOpacity
                        activeOpacity={0.8}
                        onPress={() => {
                          this.props.navigation.navigate('Podcasts');
                        }}>
                        <Bars />
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </View>
            ) : (
              <View style={styles.flexBody}>
                <ActivityIndicator size="small" color="#DE1F41" />
              </View>
            )
          ) : (
            <NoConnection />
          )}
          <Footer
            navigation={this.props.navigation}
            active={this.props.activeP}
          />
        </View>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    tracks: state.tracks.tracks,
    liveTrack: state.liveTrack.liveTrack,
    currentTrack: state.track_current.currentTrack,
    trackState: state.track_state.trackStatus,
    client: state.client.client,
    activeP: state.activePlayer.active,
    PLoader: state.activePlayer.loader,
    dimensions: state.dimensions.dimensions,
  };
};
export default connect(mapStateToProps, {
  setTrackState,
  activePlayer,
  setLiveTrack,
  setCurrentTrack
})(GPlayer);
const styles = StyleSheet.create({
  player: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  trackInfo: {
    alignSelf: 'center',
  },
  flexBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    width: '80%',
    maxWidth: 700,
  },
  coverView: {
    width: 210,
    height: 210,
    overflow: 'hidden',
    backgroundColor: '#DE1F41',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  coverViewSm: {
    width: 170,
    height: 170,
  },
  cover: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    position: 'relative',
    zIndex: 2,
  },
  coverBG: {
    position: 'absolute',
    zIndex: 1,
  },
  trackTitle: {
    fontSize: 12,
    textAlign: 'center',
    fontWeight: '500',
    color: '#181F2C',
    maxWidth: 210,
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
    lineHeight: 16,
  },
  trackTitleSm: {
    maxWidth: 170,
  },
  name: {
    fontSize: 11,
    textAlign: 'center',
    fontWeight: '100',
    color: '#fff',
    marginTop: 10,
    textTransform: 'uppercase',
    maxWidth: 210,
  },
  slider: {
    width: '80%',
    alignSelf: 'center',
    maxWidth: 700,
  },
  arrow: {
    width: 30,
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
  },
});
