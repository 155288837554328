
import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const Mic = (props) => {
    return (
        <View>
            <Svg  width={props.wid?props.wid:12} height={props.hei?props.hei:19} viewBox="0 0 12 19">
                <Path id="Path_15" data-name="Path 15" d="M115.571,224.622a.592.592,0,0,0-1.181,0,4.831,4.831,0,0,1-9.638,0,.592.592,0,0,0-1.181,0,5.769,5.769,0,0,0,5.41,5.57v1.138h-.6a2.871,2.871,0,0,0-2.962,2.763.572.572,0,0,0,.59.551h7.115a.572.572,0,0,0,.59-.551,2.871,2.871,0,0,0-2.962-2.763h-.6v-1.138A5.769,5.769,0,0,0,115.571,224.622Z" transform="translate(-103.571 -215.643)" fill={props.color?props.color:'#d9e6e6'}/>
                <Path id="Path_16" data-name="Path 16" d="M167.589,5.53a.587.587,0,0,1,.618.551.587.587,0,0,1-.618.551h-1.9V7.743h1.9a.554.554,0,1,1,0,1.1h-1.9v0a3.744,3.744,0,0,0,7.439,0v0h-1.9a.554.554,0,1,1,0-1.1h1.9V6.632h-1.9a.587.587,0,0,1-.618-.551.587.587,0,0,1,.618-.551h1.9V4.42h-1.9a.587.587,0,0,1-.618-.551.587.587,0,0,1,.618-.551h1.9v0a3.744,3.744,0,0,0-7.439,0v0h1.9a.587.587,0,0,1,.618.551.587.587,0,0,1-.618.551h-1.9V5.53Z" transform="translate(-163.406)" fill={props.color?props.color:'#d9e6e6'} />
            </Svg>
        </View>
    );
};
export { Mic }