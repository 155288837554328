
import React,{useState} from 'react';
import {TextInput, View, StyleSheet, TouchableOpacity } from 'react-native';
import {Eye} from "../assets/icons";

const FlatInput = (props) => {
  const [showpass,setShowPassword]=useState(props.secureTextEntry);
  return (
    <View style={[styles.inputContainer,props.containerStyle]} >

      <TextInput style={[styles.input,props.style]}
        allowFontScaling={false}
        placeholder={props.placeholder}
        secureTextEntry={showpass}
        autoCorrect={false}
        autoCapitalize='none'
        onChangeText={props.onChangeText}
        onChange={props.onChange}
        onFocus={props.focus}
        value={props.value}
        placeholderStyle={{ fontFamily: "Mulish" }}
        onSubmitEditing={props.onSubmitEditing}
        ref={props.refkey}
        autoFocus={props.autoFocus}
        keyboardType={props.keyboardType}
        placeholderTextColor={props.placeholderTextColor?props.placeholderTextColor:"#656565"}
        />
        {props.secureTextEntry&&
        <TouchableOpacity activeOpacity={0.9} style={styles.eye} onPress={()=>{setShowPassword(!showpass)}}>
            <Eye show={showpass} color={'#C9C9C9'}/>
        </TouchableOpacity>
        }
    </View>
  );
};
const styles = StyleSheet.create({
    inputContainer: {
        flexDirection: 'row',
        width: "100%",
        height: 50,
        alignItems:"center",
        backgroundColor:"#fff",
        marginTop:10,
        borderRadius:10
      },
      input: { 
        color:"#1E202C",
        flex:1,
        height:"100%",
        paddingLeft:15,
        paddingRight:15,
       },
      iconHolder:
      {
         width:30, 
         alignItems:"center",
      },
      eye:
      {
        height:'100%',
        width:30,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
      }
  });

export { FlatInput }