


import React from 'react';
import { View,StyleSheet,Text} from 'react-native';

const RadioLogo = (props) => {
    const size=props.size?props.size:40;
    return (
        <View style={styles.logoText}>
            <Text style={[styles.TextStyle,{fontSize:size}]}>Radio<Text style={{ color: "#E1A7A8" }}>Pro</Text><Text style={{ color: "red" }}>.</Text></Text>
        </View>
    );
};
export { RadioLogo }
 
const styles = StyleSheet.create({
      TextStyle:
      {
        padding:0,
        margin:0,
        color:"#1E202C"
      },
  });
  