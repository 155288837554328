
import React, { Component } from 'react';
import { View, StyleSheet, Text, Image, Animated, SafeAreaView, RefreshControl, FlatList, Platform, ScrollView, TouchableOpacity } from 'react-native';

import styleG from "../assets/style/styleG";
import { NewsItem, Loader, NoConnection } from '../components';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { setDimensions } from "../actions";
import { connect } from "react-redux";
import { dimensions, slidePanel, apiUrl } from "../Dev";
import { Arrow, NewsIcon } from "../assets/icons";
import axios from "axios";
import MessageBox from '../common/MessageBox';
import HTMLView from '../components/HTMLView';


class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listHeigh: 180,
            colNum: 2,
            selectNews:
            {
                title: "",
                cover: null,
                disc: "",
                date: ""
            },
            transDetail: new Animated.Value(this.props.dimensions.width),
            col_: 2,
            width_: 90,
            showBoxMsg: false,
            boxMsg: "",
            boxColor: '#28a745',
            NewsLoader: false,
            newsList: [],
            noConnection: false,
            refreshList: false
        };
    }

    gteNews() {
        if (this.props.secret) {
            this.setState({ noConnection: false });
            if (!this.state.refreshList)
                this.setState({ NewsLoader: true });
            axios.get(apiUrl + 'get-actualites', {
                'headers':
                    { 'accept': 'application/json', 'content-type': 'application/json', "Authorization": "Bearer " + this.props.secret.access_token }
            }).then(response => {
                if (response.data.status == 'error') {
                    this.setState({ boxColor: '#dc3545', boxMsg: response.data.messages.join('\n') }, () => {
                        this.setState({ showBoxMsg: true });
                        this.setState({ noConnection: true });
                    });
                }
                else if (response.data.status == 'success') {
                    this.setState({ newsList: response.data.data }, () => {
                        this.setState({ NewsLoader: false });
                    });
                }
                this.setState({ NewsLoader: false, refreshList: false });
            }).catch((error) => {
                if (error.response.status == 404 || error.response.status == 400)
                    this.setState({ noConnection: true });
                this.setState({ NewsLoader: false, refreshList: false });
            });
        }
    }
    responsiveWeb() {
        var w = this.props.dimensions.width > 1200 ? 1200 : this.props.dimensions.width;
        var col_ = 2;
        var width_ = (w - (60 + (col_ * 25))) / col_;
        if (w > 600 && w < 992) {
            col_ = 4
            width_ = (w - (60 + (col_ * 20))) / col_
        } else if (w > 800 && w < 1024) {
            col_ = 5
            width_ = (w - (60 + (col_ * 20))) / col_
        }
        else if (w > 1024) {
            col_ = 6
            width_ = (w - (60 + (col_ * 20))) / col_
        }

        this.setState({ col_: col_, width_: width_ });
        slidePanel(this.state.transDetail, this.props.dimensions.width, 10);
    }
    componentDidMount() {
        this.gteNews();
        if (Platform.OS == 'web') {
            this.responsiveWeb();
        }
        else {
            var w = this.props.dimensions.width;
            var col_ = 2;
            this.setState({ width_: parseInt((w - (30 + (col_ * 25))) / col_) });
        }
    }
    render() {
        return (
            <>
                <MessageBox bg={this.state.boxColor} msg={this.state.boxMsg} show={this.state.showBoxMsg} closeBox={() => {
                    this.setState({ showBoxMsg: false });
                }} />
                {!this.state.NewsLoader ? (!this.state.noConnection ? <View
                    onLayout={() => {
                        if (Platform.OS == 'web') {
                            this.props.setDimensions();
                            this.responsiveWeb();
                        }

                    }}
                    style={[styleG.container_]}>

                    <Header screenTitle="Actualités" navigation={this.props.navigation} />

                    <View style={[styleG.c_container, styleG.webcontainer, { flex: 1, maxHeight: Platform.OS == "web" ? "calc(100vh)" : "100%" }]}>
                        <View style={[styleG.screenheader,{paddingLeft:Platform.OS== 'web'?15:0}]}>
                            <NewsIcon color={'#1E202C'} />
                            <Text style={styleG.screenheaderText}>
                               Actualités
                            </Text>
                        </View>
                        <SafeAreaView style={{ flex:1,paddingBottom:10}}>
                            <FlatList
                                dataSet={{ item: 'listPadding' }}
                                columnWrapperStyle={{ justifyContent: "space-between" }}
                                data={this.state.newsList.slice(1, this.state.newsList.length)}
                                renderItem={({ item,index }) => {
                                    return (<NewsItem
                                        titre={item.title}
                                        img={item.image}
                                        style={{ paddingBottom: 20}}
                                        rightSize={this.state.width_}
                                        onPress={() => {
                                            slidePanel(this.state.transDetail, 0, 650);
                                            this.setState({ selectNews: { title: item.title, cover: item.image, disc: item.body, date: item.date } })
                                        }}
                                    />);

                                }}
                                keyExtractor={(item, index) => "_" + index + "_"}
                                numColumns={this.state.col_}
                                key={this.state.col_}
                                style={{ height: '100%' }}
                                ListHeaderComponent={() => {
                                    return (
                                        <View style={[styleG.c_container, styles.flatHeader, styleG.webcontainer]}>
                                            {this.state.newsList.length ?
                                                <NewsItem
                                                    titre={this.state.newsList[0].title}
                                                    img={this.state.newsList[0].image}
                                                    lastOne
                                                    rightSize={this.state.width_}
                                                    onPress={() => {
                                                        slidePanel(this.state.transDetail, 0, 650);
                                                        this.setState({
                                                            selectNews: {
                                                                title: this.state.newsList[0].title,
                                                                cover: this.state.newsList[0].image, disc: this.state.newsList[0].body,
                                                                date: this.state.newsList[0].date
                                                            }
                                                        })
                                                    }}
                                                /> : null}
                                            <Text style={[styleG.titreH4, { marginTop: 20, marginBottom: 20 }]}>Liste des actualités</Text>
                                        </View>
                                    );
                                }}
                                refreshControl={
                                    <RefreshControl refreshing={this.state.refreshList} onRefresh={() => {
                                        this.setState({ refreshList: true }, () => {
                                            this.gteNews();
                                        });
                                    }}
                                        colors={["#181A20", "#DE1F41"]}
                                        tintColor={"#fff"}
                                        progressViewOffset={150}
                                        progressBackgroundColor={'#fff'}
                                    />
                                }
                            />
                        </SafeAreaView>
                    </View>
                    <Animated.View style={[styles.actDetails, { left: this.state.transDetail }]}>
                        <ScrollView
                            stickyHeaderIndices={[0]}
                            style={styles.detailsHolder}>
                            <View style={styles.btnHolder}>
                                <TouchableOpacity activeOpacity={0.9} style={styleG.hideDetails} onPress={() => {
                                    slidePanel(this.state.transDetail, this.props.dimensions.width, 650);
                                }
                                }>
                                    <Arrow />
                                </TouchableOpacity>
                            </View>
                            <View style={styles.detailContent}>

                                <Text style={styles.NewsTitre}>
                                    {this.state.selectNews.title}
                                </Text>
                                <Text style={styles.NewsDate}>
                                    Publié le {this.state.selectNews.date}
                                </Text>
                                <View style={styles.NewsImgContent}>
                                    <View style={styles.coverBG}>
                                        <NewsIcon size={50} color={'#DE1F41'} />
                                    </View>
                                    <Image style={styles.NewsImg} source={{ uri: this.state.selectNews.cover }} />
                                </View>
                                <View>
                                    <HTMLView value={this.state.selectNews.disc} />
                                </View>
                            </View>
                        </ScrollView>
                    </Animated.View>
                    <Footer navigation={this.props.navigation} active={3} />
                </View> : <NoConnection />)
                    :
                    <Loader />
                }
            </>
        );
    }
};
const styles = StyleSheet.create({
    actDetails:
    {
        width: Platform.OS == "web" ? "100%" : dimensions.width,
        height: Platform.OS == "web" ? "100%" : dimensions.height,
        position: Platform.OS == "web" ? "fixed" : 'absolute',
        zIndex: 99,
        top: 0,
        backgroundColor: '#FAF9FC',
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        paddingTop: 50,
        paddingBottom:20
    },
    detailsHolder:
    {
        width: "100%",
        flex: 1,
        maxHeight: Platform.OS == "web" ? "calc(100vh - 95px)" : "100%",
        maxWidth: Platform.OS == "web" ? 700 : "100%",
    },
    detailContent:
    {
        paddingBottom: 15,
        paddingTop: 15,
        marginRight: 30,
        marginLeft: 30
    },
    NewsTitre: {
        color: "#1E202C",
        fontSize: 16,
        fontWeight: "600"
    },
    NewsDate:
    {
        marginTop: 15,
        color: "#4F5672",
        fontSize: Platform.OS == "web" ? 13 : 11,
        fontWeight: "400"
    },
    NewsImgContent:
    {
        width: "100%",
        height: 140,
        borderRadius: 10,
        overflow: "hidden",
        marginTop: 30,
        backgroundColor: "#FFE8E8"
    },
    NewsImg:
    {
        width: "100%",
        height: "100%",
        resizeMode: "cover"
    },
    btnHolder:
    {
        marginRight: 30,
        marginLeft: 30,
        paddingTop: 15,
        paddingBottom: 10
    },
    flatHeader:
    {
        marginTop: 20,
        paddingLeft: 0,
        paddingRight: 0,
    },
    coverBG:
    {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center"
    }
});

const mapStateToProps = state => {
    return {
        dimensions: state.dimensions.dimensions,
        secret: state.secret.secret
    }
}
export default connect(mapStateToProps, { setDimensions })(News);