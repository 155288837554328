
import React, { Component } from 'react';
import { View,StyleSheet,Text} from 'react-native';
import styleG from "../assets/style/styleG";
import { Triangle } from "../assets/icons";

class NoConnection extends Component {
    render() {
        return (
            <View style={[styleG.container_,styles.holder]}>
                   <View>
                      <View style={styles.iconHolder}><Triangle size={60} color={'#ffb100'}/></View> 
                       <Text style={styles.textInfo}>
                          Service momentanément indisponible. Veuillez réessayer plus tard
                       </Text>
                   </View>
            </View>
        );
    }
};
const styles=StyleSheet.create({
    holder:
    {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        padding:20,
    },
    iconHolder:
    {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        marginBottom:25
    },
    textInfo:
    {
        fontSize:19,
        textAlign:'center',
        maxWidth:500,
        color:"#DE1F41"
    }
});
export {NoConnection}