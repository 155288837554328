import { SETCURRENT } from "../actions/Types"
const INTIAL_STATE =
{
    currentTrack: {
        id: -2,
        url: '',
        title: 'UNKNOWN',
        artist: 'UNKNOWN',
        artwork: null,
        duration: 1,
        isLive: true,
        started_at: '',
        end_at: '',
    },
}
export default (state = INTIAL_STATE, action) => {

    switch (action.type) {
        case SETCURRENT:
            return {
                ...INTIAL_STATE,
                currentTrack: action.current,
            }
        default:
            return state;
    }
}