
import {SETCLIENT} from "../actions/Types"
const INTIAL_STATE=
{
    client:null,
}
export default (state=INTIAL_STATE,action)=>{

    switch (action.type) {
        case SETCLIENT:
            return {...INTIAL_STATE,client:action.client}
        default:
            return state;
    }
}