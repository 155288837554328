import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const Setting = (props) => {
    return (
        <View>
            <Svg width={props.size?props.size:14} height={props.size?props.size:14} viewBox="0 0 14 14">
                <Path d="M193.938,192a1.938,1.938,0,1,0,1.938,1.938A1.94,1.94,0,0,0,193.938,192Z" transform="translate(-186.938 -186.938)" fill={props.color?props.color:'#fff'} />
                <Path d="M13.527,5.81l-.772-.149a1,1,0,0,1-.621-1.582l.485-.661a.582.582,0,0,0-.058-.757L11.513,1.614a.583.583,0,0,0-.739-.071l-.652.44A1,1,0,0,1,8.565,1.3L8.44.495A.584.584,0,0,0,7.863,0H6.383A.584.584,0,0,0,5.81.473l-.192,1a1.005,1.005,0,0,1-1.551.642l-.841-.569a.583.583,0,0,0-.739.071L1.44,2.661a.582.582,0,0,0-.058.757l.485.662a1,1,0,0,1-.621,1.581L.473,5.81A.584.584,0,0,0,0,6.383v1.48a.583.583,0,0,0,.495.577l.81.125a1.005,1.005,0,0,1,.68,1.558l-.44.651a.583.583,0,0,0,.071.739L2.661,12.56a.583.583,0,0,0,.757.058l.662-.485a1,1,0,0,1,1.581.621l.149.772A.584.584,0,0,0,6.383,14h1.48a.583.583,0,0,0,.577-.495l.088-.569a1.006,1.006,0,0,1,1.59-.658l.465.341a.583.583,0,0,0,.757-.058l1.047-1.047a.583.583,0,0,0,.071-.739l-.44-.652a1,1,0,0,1,.68-1.557l.81-.125A.583.583,0,0,0,14,7.863V6.383A.584.584,0,0,0,13.527,5.81ZM7,9.917A2.917,2.917,0,1,1,9.917,7,2.92,2.92,0,0,1,7,9.917Z" fill={props.color?props.color:'#fff'} />

            </Svg>
        </View>
    );
};
export { Setting }