
import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const Live = (props) => {
    return (
        <View>
            <Svg width={props.wid?props.wid:22.538} height={props.hei?props.hei:13.684} viewBox="0 0 22.538 13.684">
                <Path d="M210.593,88.409a1.9,1.9,0,1,1,0-2.688A1.907,1.907,0,0,1,210.593,88.409Zm0,0" transform="translate(-197.974 -80.223)" fill={props.color?props.color:'#fff'}/>
                <Path  d="M292.709,53.853a.906.906,0,0,1-.641-.263.888.888,0,0,1,0-1.266,3.711,3.711,0,0,0,0-5.3.888.888,0,0,1,0-1.266.912.912,0,0,1,1.28,0,5.487,5.487,0,0,1,0,7.831A.907.907,0,0,1,292.709,53.853Zm0,0" transform="translate(-276.368 -42.834)" fill={props.color?props.color:'#fff'}/>
                <Path  d="M327.662,13.687a.905.905,0,0,1-.641-.264.9.9,0,0,1,0-1.271,7.457,7.457,0,0,0,0-10.613.9.9,0,0,1,0-1.271.909.909,0,0,1,1.28,0,9.247,9.247,0,0,1,0,13.159A.906.906,0,0,1,327.662,13.687Zm0,0" transform="translate(-308.512 -0.003)" fill={props.color?props.color:'#fff'}/>
                <Path  d="M135.685,53.852a.908.908,0,0,1-.639-.262,5.487,5.487,0,0,1,0-7.831.912.912,0,0,1,1.28,0,.888.888,0,0,1,0,1.266,3.711,3.711,0,0,0,0,5.3.888.888,0,0,1,0,1.266A.907.907,0,0,1,135.685,53.852Zm0,0" transform="translate(-129.487 -42.833)" fill={props.color?props.color:'#fff'}/>
                <Path  d="M82.955,13.685a.905.905,0,0,1-.639-.263,9.248,9.248,0,0,1,0-13.159.909.909,0,0,1,1.28,0,.894.894,0,0,1,0,1.271,7.458,7.458,0,0,0,0,10.613.9.9,0,0,1,0,1.271A.905.905,0,0,1,82.955,13.685Zm0,0" transform="translate(-79.566 -0.001)" fill={props.color?props.color:'#fff'}/>
            </Svg>
        </View>
    );
};
export { Live }