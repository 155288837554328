
import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const UserIcon = (props) => {
    return (
        <View>
            <Svg width={props.size?props.size:16} fill={props.color?props.color:'#fff'} height={props.size?props.size:16} viewBox="0 0 16 16">
                <Path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                <Path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
            </Svg>
        </View>
    );
};
export { UserIcon }