import {INFOS} from "../actions/Types"
const INTIAL_STATE=
{
    infos:null,
}

export default (state=INTIAL_STATE,action)=>{

    switch (action.type) {
        case INFOS:
            return {...INTIAL_STATE,infos:action.Infos}
        default:
            return state;
    }
}