import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'

class HTMLView extends Component {
    render() {
        return (
            <div className="webFont">
                {ReactHtmlParser(this.props.value)}
            </div>
        );
    }
};

export default HTMLView