
import React, { Component } from 'react';
import { View, StyleSheet, StatusBar, Platform, Image } from 'react-native';
import { connect } from "react-redux";
import { getStatusBarHeight } from 'react-native-status-bar-height';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Arrow } from "../assets/icons";
import { RadioLogo } from "../assets/icons";

class Header extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showOverLay: false,
         isfromurl: 0,
         logoSize: { width: 80, height: 60 },
         headerHeight: 0
      }
   }
   componentDidMount() {
      this.setState({ headerHeight: getStatusBarHeight() });
      this.props.client && StatusBar.setBarStyle('dark-content');
      if (this.props.client) {
         if (this.props.client.logo_client) {
            Image.getSize(this.props.client.logo_client, (width, height) => {
               if(width/2>=height)
               {
                  this.state.logoSize.height=45;
               }
               let percent = this.state.logoSize.height / height,
                  h = this.state.logoSize.height,
                  w = parseInt(width * percent);
                  this.setState({ logoSize: { width: w, height: h } });
            });
         }
      }
   }
   hideOverlay() { this.setState({ showOverLay: false }); }

   render() {
      return (
         <View style={[styles.header, { paddingTop: this.state.headerHeight }, Platform.OS == 'web' && styles.space]}>
            <View style={[styles.headerNav,!this.props.client && styles.alCenter,{height: this.state.logoSize.height}]}>
               {this.props.back && <View style={styles.arrow}>
                  <TouchableOpacity style={styles.able} activeOpacity={0.8} onPress={this.props.goTo}>
                     <Arrow color={'#000'} />
                  </TouchableOpacity>
               </View>}
               {!this.props.client && <View style={styles.arrow}>
                  <TouchableOpacity activeOpacity={0.8} onPress={()=>{
                     this.props.navigation.navigate('Login');
                     }}>
                     <Arrow color={'#000'} />
                  </TouchableOpacity>
               </View>}

               <View>
                  {this.props.client ?<Image source={{ uri: this.props.client.logo_client }} style={[styles.bankLogo, { width: this.state.logoSize.width, height: this.state.logoSize.height }]} />
                  :<RadioLogo size={20}/>}
               </View>
            </View>
         </View>
      );
   }

};
const styles = StyleSheet.create({
   header:
   {
      width: "100%",
      backgroundColor: '#FAF9FC',
      paddingBottom: 10,
      borderBottomWidth: 3,
      borderBottomColor: '#ebebeb63',
   },
   space: {
      marginTop: 10
   },
   headerNav:
   {
      paddingRight: 25,
      paddingLeft: 35,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: 'flex-end',
      position: "relative",
      zIndex: 2,
   },
   alCenter:{
      alignItems: 'center',
   },
   bankLogo: {
      resizeMode: 'cover'
   },
   setting:
   {
      padding: 5,
      paddingRight: 15,
      paddingLeft: 15,
      alignSelf: "flex-end"
   },
   btnHolder:
   {
      alignSelf: 'center',
      maxWidth: Platform.OS == "web" ? 700 : "100%",
      width: '100%'
   },
   headerTitle:
   {
      flex: 1,
      alignItems: 'center',
      justifyContent: "center",
      display: 'flex',
      flexDirection: 'row'
   },
   backBtn:
   {
      position: 'absolute', zIndex: 999,
      top: 100, left: 50, backgroundColor: "#fff",
      height: 40,
      width: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 20
   },
   arrow: {
      width: 50,
      height: '100%',
      left: 15,
      position: 'absolute',
   },
   able:{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
   }
});
const mapStateToProps = state => {
   return {
      client: state.client.client,
      dimensions: state.dimensions.dimensions,
   }
}
export default connect(mapStateToProps, {})(Header);