
import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, Platform } from 'react-native';
import { StackActions, NavigationActions } from 'react-navigation';
import { Privacy, Setting, PowerOff } from "../assets/icons";
import { connect } from "react-redux";
import { RadioLogo } from "../assets/icons";
import AsyncStorage from '@react-native-async-storage/async-storage';
import TrackPlayer from "../services/TrackPlayer";
const resetLogin = StackActions.reset({
    index: 0,
    actions: [NavigationActions.navigate({ routeName: 'Login' })],
});
class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };
    render() {
        return (
            <View style={[styles.Options]}>
                <TouchableOpacity style={[styles.Option, styles.userName]} activeOpacity={0.9}>
                    <Text numberOfLines={2} style={styles.userNameText}>{'Bonjour \n' + (this.props.user ? this.props.user.first_name + " " + this.props.user.last_name : "")}
                    </Text>

                </TouchableOpacity>
                <TouchableOpacity dataSet={{ item: 'primaryBG' }} onPress={() => {
                    this.props.navigation.navigate('Profile');
                    this.props.onPress();
                }}
                    style={[styles.Option]} activeOpacity={0.9}>
                    <Setting color={this.props.active == 1?'#DE1F41':'#1E202C'}/>
                    <Text style={[styles.OptionText,this.props.active == 1 && styles.TextActive]}>Mes informations</Text>
                </TouchableOpacity>
                <TouchableOpacity dataSet={{ item: 'primaryBG' }} onPress={() => {
                    this.props.navigation.navigate('Privacy');
                    this.props.onPress();
                }}
                    style={[styles.Option]} activeOpacity={0.9}>
                    <Privacy color={this.props.active == 2?'#DE1F41':'#1E202C'}/>
                    <Text style={[styles.OptionText,this.props.active == 2 && styles.TextActive]}>Mentions légales</Text>
                </TouchableOpacity>
                <TouchableOpacity dataSet={{ item: 'primaryBG' }} onPress={async () => {
                    try {
                        await AsyncStorage.setItem('@token', '');
                        if (this.props.trackState)
                        {
                            TrackPlayer.stop();
                            if(Platform.OS != 'web')
                              TrackPlayer.reset();
                        }
                        if (Platform.OS === 'web')
                            location.reload();
                        setTimeout(() => {
                            this.props.navigation.dispatch(resetLogin);
                        }, 200);
                    } catch (e) {
                    }
                }}
                    style={[styles.Option]} activeOpacity={0.9}>
                    <PowerOff color={this.props.active == 3?'#DE1F41':'#1E202C'}/>
                    <Text style={[styles.OptionText,this.props.active == 3 && styles.TextActive]}>Se déconnecter</Text>
                </TouchableOpacity>
                <View style={styles.logoHolder}>
                    <RadioLogo size={8} />
                </View>
            </View>
        );
    }

};
const styles = StyleSheet.create({
    Options:
    {
        padding: 20,
        paddingTop: 10,
        paddingBottom: 10,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        alignSelf: "baseline",
        position: "absolute",
        right: 35,
        bottom: 90,
        borderRadius: 10,
        borderWidth: 3,
        borderColor: '#ebebeb63',
    },
    Option:
    {
        padding: 15,
        paddingTop: 10,
        paddingBottom: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "flex-start",
        borderRadius: 8,
        marginTop: 5
    },
    OptionText:
    {
        color: "#1E202C",
        fontSize: 11,
        fontWeight: 'bold',
        marginLeft: 7
    },
    userName:
    {
        paddingTop: 0,
    },
    userNameText:
    {
        color: "#1E202C",
        fontSize: 13,
        fontWeight: 'bold'
    },
    Logo:
    {
        width: 46, height: 8, alignSelf: "center",
        marginTop: 10
    },
    logoHolder:{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    TextActive: {
        color: "#DE1F41",
    }

});
const mapStateToProps = state => {
    return {
        user: state.user.user,
        trackState: state.track_state.trackStatus,
    }
}
export default connect(mapStateToProps, {})(Options);