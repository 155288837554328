import Svg, { Path, G } from 'react-native-svg';
import React from 'react';
import { View, StyleSheet } from 'react-native';
const Switch = (props) => {
    return (
        <View style={{ transform: [{ rotate: props.prev ? '180deg' : "0deg" }] }}>
            <Svg width={props.size ? props.size : 18} height={props.size ? props.size : 18} fill={props.color} viewBox="0 0 16 16">
                <Path d="M12.5 4a.5.5 0 0 0-1 0v3.248L5.233 3.612C4.693 3.3 4 3.678 4 4.308v7.384c0 .63.692 1.01 1.233.697L11.5 8.753V12a.5.5 0 0 0 1 0V4z" />
            </Svg>
        </View>
    );
};
export { Switch }
const styles = StyleSheet.create({

});