import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity,Platform, Text, Image } from 'react-native';

import { ControlBtn } from '../common';
import { Play, Mic } from "../assets/icons";

class BoxItem extends Component {
    render() {
        return (
            <View  dataSet={{item:'boxScale'}} style={[styles.box,this.props.currentTrack&&styles.currentBg]} >
                <TouchableOpacity style={styles.able} onPress={this.props.controlPress} activeOpacity={0.9}></TouchableOpacity>
                <View style={[styles.imageView]}>
                    <Image
                        style={[styles.img]}
                        source={{ uri: this.props.img }}
                    />
                    <View style={styles.coverBG}>
                        <Mic hei={33} wid={22} />
                    </View>
                </View>
                <View style={[styles.contentView]}>
                    <Text style={[styles.contentTitre,
                    { fontSize: (Platform.OS == "web" || this.props.lastOne) ? (this.props.lastOne ? 18 : 14) : 12,color:"#1E202C", }]}
                    numberOfLines={3}
                    >
                        {this.props.titre}
                    </Text>
                    <Text style={[styles.contentDuration, { fontSize: Platform.OS == "web" ? 11 : 9,color:this.props.currentTrack?"#1E202C":"#A6ACC4",  }]}>Durée {this.props.duration}</Text>
                </View>
            </View>
        );
    }
};
const styles = StyleSheet.create({
    box:
    {
        height: 80,
        maxHeight: 80,
        minHeight: 80,
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        overflow: "hidden",
        marginTop: 10
    },
    currentBg:{
        backgroundColor: '#d5d5d5',
        borderColor: '#efefef',
        borderWidth:3,
        borderRadius:10
    },
    lastOne:
    {
        height: 115,
        maxHeight: 115,
        minHeight: 115,
        padding: 10
    },
    imageView:
    {
        width: 90,
        height: "100%",
        overflow: "hidden",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#DE1F41",
    },
    imageViewLast:
    {
        width: 104,
        borderRadius: 5,
        overflow: 'hidden',
    },
    coverBG:
    {
        position: "absolute",
        zIndex: 1,
    },
    img:
    {
        width: "100%",
        height: "100%",
        resizeMode: "cover",
        zIndex: 3,
        position: "relative"
    },
    contentView:
    {
        flex: 1,
        marginLeft: 15,
    },
    contentTitre:
    {
        fontWeight: "normal",
    },
    contentDuration:
    {
        fontWeight: "normal",
        color: "#C6C2C2",
        marginTop: 10
    },
    ControlBtn: {
        height: "100%",
        padding: 8,
        display: "flex",
        justifyContent: "flex-end",
    },
    ControlBtnAbs:
    {
        position: "absolute",
        bottom: 10,
        right: 10,
        height: "auto",
        padding: 0,
    },
    able:
    {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        zIndex:10,
        top:0,left:0,
        backgroundColor:"transparent",
        position:"absolute"
    },
});

export { BoxItem }