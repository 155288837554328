import { TRACKSTATE } from "../actions/Types"
const INTIAL_STATE =
{
    trackStatus: false,
}
export default (state = INTIAL_STATE, action) => {

    switch (action.type) {
        case TRACKSTATE:
            return {
                ...INTIAL_STATE,trackStatus: action.trackStatus,
            }
        default:
            return state;
    }
}