
import Svg, { Path, G } from 'react-native-svg';
import React from 'react';
import { View, StyleSheet } from 'react-native';
const Shuffle = (props) => {
    return (
        <View >
            <Svg width="15" height="14" viewBox="0 0 13.863 12.707">
                <Path d="M.578,107.815H1.733a2.881,2.881,0,0,1,1.444.386.578.578,0,1,0,.58-1,4.045,4.045,0,0,0-2.024-.542H.578a.578.578,0,0,0,0,1.155Z" transform="translate(0 -104.35)" fill={props.color} />
                <Path  d="M8.664,24.8H9.819v1.733a.578.578,0,0,0,.939.451l2.888-2.31a.578.578,0,0,0,0-.9l-2.888-2.31a.578.578,0,0,0-.939.451v1.733H8.664a4.048,4.048,0,0,0-4.043,4.043,2.891,2.891,0,0,1-2.888,2.888H.578a.578.578,0,0,0,0,1.155H1.733a4.048,4.048,0,0,0,4.043-4.043A2.891,2.891,0,0,1,8.664,24.8Z" transform="translate(0 -21.336)" fill={props.color} />
                <Path  d="M235.369,279.757l-2.888-2.31a.577.577,0,0,0-.939.451v1.733h-1.155a2.875,2.875,0,0,1-1.6-.481.578.578,0,0,0-.639.962,4.029,4.029,0,0,0,2.236.674h1.155v1.733a.578.578,0,0,0,.939.451l2.888-2.31a.578.578,0,0,0,0-.9Z" transform="translate(-221.723 -270.389)" fill={props.color} />
            </Svg>
        </View>
    );
};
export { Shuffle }
const styles = StyleSheet.create({

});