import React, { Component } from 'react';
import {
    View,
    StyleSheet,
    Text,
    TextInput,
    FlatList,
    Platform,
    ActivityIndicator
} from 'react-native';
import styleG from '../assets/style/styleG';
import { PlayList, Loader } from '../components';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { connect } from 'react-redux';
import { apiUrl } from '../Dev';
import { Search } from '../assets/icons';
import axios from 'axios';
import { setDimensions, setPlaylist } from '../actions';
import TrackPlayer from '../services/TrackPlayer';
import { Mic } from "../assets/icons";

class PlayLists extends Component {
    constructor() {
        super();
        this.state = {
            seachVal: '',
            filtredData: [],
            playlists: [],
            col_: 2,
            width_: 90,
            searchLoader: false,
            PlayLoader:false
        };
    }
    componentDidMount() {
        this.getPlayLists();
        if (Platform.OS == 'web') {
            this.responsiveWeb();
        } else {
            var w = this.props.dimensions.width;
            var col_ = 2;
            this.setState({ width_: parseInt((w - (30 + col_ * 25)) / col_) });
        }
    }

    async switchPlayList(item) {
        if (this.props.playlist && this.props.playlist.id != item.id && this.props.trackState && !this.props.currentTrack.isLive) {
            TrackPlayer.stop();
            if (Platform.OS != 'web')
                await TrackPlayer.reset();
        }
        this.props.setPlaylist(item);
        this.props.navigation.navigate('Podcasts');
    }

    getPlayLists() {
        this.setState({ PlayLoader: true });
        axios.get(apiUrl + 'get-playlists', {
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + this.props.secret.access_token,
                },
            })
            .then(res => {
                if (res.data.status == 'error') {

                }
                else if (res.data.status == 'success') {
                    if(res.data.data.length === 1)
                    {
                        this.switchPlayList(res.data.data[0]);
                    }
                    this.setState({ playlists: res.data.data, filtredData: res.data.data });
                }
                this.setState({ PlayLoader: false });
            })
            .catch((error)=> {
                this.setState({ PlayLoader: false });
             });
    }

    responsiveWeb() {
        var w =
            this.props.dimensions.width > 1200 ? 1200 : this.props.dimensions.width;
        var col_ = 2;
        var width_ = (w - (60 + col_ * 25)) / col_;
        if (w > 600 && w < 992) {
            col_ = 4;
            width_ = (w - (60 + col_ * 20)) / col_;
        } else if (w > 800 && w < 1024) {
            col_ = 5;
            width_ = (w - (60 + col_ * 20)) / col_;
        } else if (w > 1024) {
            col_ = 6;
            width_ = (w - (60 + col_ * 20)) / col_;
        }

        this.setState({ col_: col_, width_: width_ });
    }
    search(text) {
        this.setState({ seachVal: text });
        setTimeout(() => {
            if (this.state.seachVal === text) {
                const results = [];
                this.setState({ searchLoader: true })
                this.state.playlists.map((element) => {
                    var c = element.name;
                    if (c.toUpperCase().includes(text.toUpperCase()))
                        results.push(element);
                });
                this.setState({ filtredData: results }, () => {
                    setTimeout(() => {
                        this.setState({ searchLoader: false });
                    }, 500);
                });
            }
        }, 600);
    }

    render() {
        return (
            <>
                {!this.state.PlayLoader ? <View style={styleG.container_} dataSet={{ item: 'thisdiv' }}>

                    {/* Header */}
                    <Header screenTitle="Podcasts" navigation={this.props.navigation} />
                    {/* Header */}
                    <View
                        style={[styleG.c_container, styleG.webcontainer, styles.body]}
                        onLayout={() => {
                            if (Platform.OS == 'web') {
                                this.props.setDimensions();
                                this.responsiveWeb();
                            }
                        }}>
                        <View style={styleG.screenheader}>
                            <Mic color={'#1E202C'} />
                            <Text style={styleG.screenheaderText}>
                                Podcasts
                            </Text>
                        </View>
                        <View style={styleG.serchGroup}>
                            <TextInput
                                style={[styleG.serchGroupInput]}
                                allowFontScaling={false}
                                placeholder={'Recherche'}
                                autoCorrect={false}
                                autoCapitalize="none"
                                onChangeText={seachVal => {
                                    this.search(seachVal);
                                }}
                                value={this.state.seachVal}
                                placeholderStyle={{ fontFamily: 'Mulish' }}
                                autoFocus={false}
                                placeholderTextColor={'#656565'}
                            />
                            <Search />
                        </View>
                        {!this.state.searchLoader ? <FlatList
                            dataSet={{ item: 'listPaddingRight' }}
                            style={styles.flatList}
                            columnWrapperStyle={{ justifyContent: 'space-between', gap: 10 }}
                            data={this.state.filtredData}
                            keyExtractor={item => item.id}
                            renderItem={({ item }) => {
                                return (
                                    <PlayList
                                        name={item.name}
                                        rightSize={this.state.width_}
                                        cover={item.image_url
                                            ? item.image_url
                                            : this.props.client
                                            ? this.props.client.current_show.image_url
                                            : null}
                                        count={item.podcasts_count}
                                        onPress={() => {
                                            this.switchPlayList(item);
                                        }}
                                        current={
                                            (this.props.playlist && this.props.playlist.id) === item.id
                                                ? true
                                                : false
                                        }
                                    />
                                );
                            }}
                            numColumns={this.state.col_}
                            key={this.state.col_}
                        /> : <View style={styleG.searchLoader}>
                            <ActivityIndicator size="small" color="#DE1F41" />
                        </View>}
                    </View>
                    <Footer navigation={this.props.navigation} active={1} />
                </View> :
                    <Loader />
                }
            </>
        );
    }
}

const styles = StyleSheet.create({
    body: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxHeight: Platform.OS == 'web' ? '100vh' : '100%',
        flex: 1,
        paddingBottom: 10,
    },
    flatList: {
        flex: 1,
        width: '100%',
    },
});

const mapStateToProps = state => {
    return {
        infos_user: state.infos.infos,
        secret: state.secret.secret,
        dimensions: state.dimensions.dimensions,
        playlist: state.playList.playlist,
        trackState: state.track_state.trackStatus,
        currentTrack: state.track_current.currentTrack,
        client: state.client.client
    };
};
export default connect(mapStateToProps, { setDimensions, setPlaylist })(
    PlayLists,
);
