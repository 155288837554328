
import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const Bars = (props) => {
    return (
        <View>
            <Svg  width="18.546" height="15.174" viewBox="0 0 18.546 15.174">
            <Path d="M2.5,17.831a.843.843,0,0,1,.843-.843H20.2a.843.843,0,0,1,0,1.686H3.343A.843.843,0,0,1,2.5,17.831Zm0-6.744a.843.843,0,0,1,.843-.843H20.2a.843.843,0,0,1,0,1.686H3.343A.843.843,0,0,1,2.5,11.087Zm0-6.744A.843.843,0,0,1,3.343,3.5H20.2a.843.843,0,0,1,0,1.686H3.343A.843.843,0,0,1,2.5,4.343Z" transform="translate(-2.5 -3.5)"
               fill={props.color?props.color:'#000'} />
            </Svg>
        </View>
    );
};
export { Bars }