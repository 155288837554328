import React,{Component} from "react";
import {View,StyleSheet,Platform,Text} from "react-native";
import Slider from 'react-input-slider';
import Slider_ from '../common/Slider_';
import TrackPlayer from "../services/TrackPlayer";
class Volume extends  Component{
    constructor()
    {
        super();
        this.state={
            volume:0
        }
    }
    volumeChange(val)
    {
       this.setState({volume:val},function(){
           TrackPlayer.setVolume(this.state.volume);
       });
    }
    componentDidMount()
    {
        this.volumeChange(Platform.OS=="web"?this.state.volume:this.state.volume+1);
    }
    render(){
        return(
            <View style={styles.volumeView}>
               {Platform.OS=="web"?<Slider
                 styles={slideStyle}
                 ymax={1}
                 ymin={0}
                 ystep={0.1} 
                 style={{height:100}}
                 axis="y" 
                 y={this.state.volume}
                 onChange={({ y }) => this.volumeChange(y)} />
                 :<Slider_
                 orientation={'vertical'} style={{height:100}} duration={1} step={0.1}
                 value={this.state.volume}
                 valueChange={(val)=>{
                    this.volumeChange(val);
                 }}
                 />}
            </View> 
        );
    }
}
const styles=StyleSheet.create({
    volumeView:
    {
        position:"absolute",
        zIndex:3,
        right:-55,
        top:55,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column",
        width:30,
    },
    
});
const slideStyle =
{
    track: {
        backgroundColor: '#DE1F41',
        width: 4,
    },
    active: {
        backgroundColor: '#1C1F26',
        width: 4,
    },
    thumb: {
        width: 10,
        height: 10,
        backgroundColor: '#DE1F41',
        right:0
    }
}

export {Volume} 