

import React, { Component } from 'react';
import { View,ActivityIndicator,StyleSheet,Platform} from 'react-native';

class Loader extends Component {
    render() {
        return (
            <View style={styles.loaderHolder}>
               <ActivityIndicator size="large" color="#DE1F41" />
            </View>
        );
    }
};
const styles=StyleSheet.create({
    loaderHolder:
    {
        width:'100%',
        height:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'#FAF9FC'
    }
});
export {Loader}