import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import TrackPlayer from "../services/TrackPlayer";
const Slider_ = (props) => {
    return (
        <View style={styles.container}>
            {!props.simpleOne ? <>
                <View style={{ width: "100%", marginBottom: 20 }}>
                    <Text style={[styles.duration, { left: 0 }]}>{props.minLabel}</Text>
                    <Text style={[styles.duration, { right: 0 }]}>{props.maxLabel}</Text>
                </View>
                <Slider
                    style={{ width: "100%" }}
                    value={props.value}
                    min={0}
                    max={props.duration}
                    step={props.step}
                    disabled={props.disbled}
                    trackStyle={slideStyle.track}
                    railStyle={slideStyle.rail}
                    handleStyle={slideStyle.handle}
                    onChange={(val) => {
                        TrackPlayer.seekTo(val);
                    }}
                />
            </> :
                <Slider
                    style={{ width: "100%" }}
                    value={props.value}
                    min={props.min}
                    max={props.duration}
                    step={props.step}
                    trackStyle={props.trackStyle}
                    railStyle={props.railStyle}
                    handleStyle={props.handleStyle}
                    onChange={(val) => {
                        props.valueChange(val);
                    }}
                />
            }
        </View>
    );
};

const styles = StyleSheet.create({
    container:
    {
        alignSelf: "center",
        width: "100%",
        marginTop: 30
    },
    duration:
    {
        position: "absolute",
        color: "#737992",
        fontSize: 11,
        fontFamily: "Arial",
        fontWeight: "bold",
    },
    left:
    {
        left: 0
    }
});
const slideStyle =
{
    rail: {
        backgroundColor: '#E4E8F4',
        height: 5,

    },
    track: {
        backgroundColor: '#DE1F41',
        height: 5,
    },
    handle: {
        width: 10,
        height: 10,
        backgroundColor: '#DE1F41',
        borderWidth: 0,
        marginTop: -3,
        outline: "none"
    }
}

export default Slider_;