import React, { Component } from "react";
import { View, StyleSheet, Animated, } from "react-native";
import { dimensions, slidePanel,apiUrl,resetLogin,resetLive,resetRegister,resetHelp,ResetPassWord,privacyPolicy} from "../Dev";
import { RadioLogo } from "../assets/icons";
import { Platform } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from "axios";
import {connect} from 'react-redux';
import { setUser,clientUser,setSecrets } from "../actions";
import MessageBox from '../common/MessageBox';



class Splash extends Component {
    constructor() {
        super();
        this.state = {
            logoAnime: new Animated.Value(0),
            progressHolderAnime: new Animated.Value(0),
            IfError: false,
            progressAnime: new Animated.Value(0),
            token:'',
            showBoxMsg: false,
            boxMsg: "",
            boxColor: '#28a745',
        }
    }

    async checkifUser() {
        var token,email,password;
        try {
            token = await AsyncStorage.getItem('@token');
            email = await AsyncStorage.getItem('@email');
            password = await AsyncStorage.getItem('@password');
            if(token&&token!='')
               {
                    let formData = new FormData();
                    formData.append('email', email);
                    formData.append('password', password);
                    axios.post(apiUrl + 'login', formData, { 'headers': { "Authorization": "Basic cmZjYWRtaW46UEBzc2VSQw==" } })
                        .then(response => {
                            if (response.data.status == 'error') {
                                this.props.navigation.dispatch(resetLogin);
                            }
                            else if (response.data.status == 'success') {
                                this.props.setUser(response.data.user);
                                this.props.clientUser(response.data.client);
                                this.props.setSecrets(response.data.secrets);
                                this.props.navigation.dispatch(resetLive);
                            }
                        }).catch((error) => {
                            if(error.message=='Network Error')
                            {
                                this.setState({ boxColor: '#dc3545', boxMsg: 'Erreur réseau'}, () => {
                                    this.setState({ showBoxMsg: true });
                                })
                            }
                            else
                            {
                                this.props.navigation.dispatch(resetLogin);
                            }
                        });
               }
               else{
                   this.props.navigation.dispatch(resetLogin);
               }
        } catch (e) {
        }
    }  

    componentDidMount() {
        Animated.parallel([
            Animated.spring(this.state.logoAnime, {
                toValue: 1,
                duration: 3000,
                tension: 16,
                friction: 15,
                useNativeDriver: false,
            }).start()
        ]).start(() => {
            setTimeout(() => {
                Animated.parallel([
                    Animated.spring(this.state.progressHolderAnime, {
                        toValue: 1,
                        duration: 3000,
                        tension: 16,
                        friction: 15,
                        useNativeDriver: false,
                    }).start()
                ]).start(() => {
                    setTimeout(() => {
                        slidePanel(this.state.progressAnime, 150, 1000, () => {
                            if (Platform.OS == "web") {
                                const urlParams = new URLSearchParams(window.location.search);
                                const invit = urlParams.get('invit');
                                const token = urlParams.get('token');
                                const privacy_policy = urlParams.get('privacy-policy');
                                const help = urlParams.get('help');
                                if (invit) {
                                    this.props.navigation.dispatch(resetRegister);
                                    return;
                                }
                                if (help) {
                                    this.props.navigation.dispatch(resetHelp);
                                    return;
                                }
                                if (token) {
                                    this.props.navigation.dispatch(ResetPassWord);
                                    return;
                                }
                                if (privacy_policy) {
                                    this.props.navigation.dispatch(privacyPolicy);
                                    return;
                                }
                                this.checkifUser();
                            }
                            else{
                                this.checkifUser();
                            }
                           
                        })
                    }, 500);
                })
            }, 1000);
        })
    }
    render() {
        const interpo = this.state.logoAnime.interpolate({
            inputRange: [0, 1],
            outputRange: [(dimensions.width / 2) * -1, 0]
        })
        const interpolist = this.state.progressHolderAnime.interpolate({
            inputRange: [0, 1],
            outputRange: [50, 0]
        })
        return (
            <>
            <MessageBox bg={this.state.boxColor} msg={this.state.boxMsg} show={this.state.showBoxMsg} closeBox={() => {
                    this.setState({ showBoxMsg: false });
                }} />
                <View style={styles.intro}>
                    <Animated.View style={[{ opacity: this.state.logoAnime, left: interpo }]}>
                        <RadioLogo />
                    </Animated.View>
                    <Animated.View style={[{ opacity: this.state.progressHolderAnime, top: interpolist, width: "100%" }]}>
                        <View style={styles.progressBar}>
                            <View style={styles.rail}></View>
                            <Animated.View style={[styles.selectedRail, { width: this.state.progressAnime }]}>
                            </Animated.View>
                        </View>
                    </Animated.View>
                </View>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
    }
}
export default connect(mapStateToProps, { setUser,clientUser,setSecrets})(Splash); 
const styles = StyleSheet.create({
    intro:
    {
        flex: 1,
        backgroundColor: "#FAF9FC",
        alignItems: "center",
        justifyContent: "center",
    },
    progressBar:
    {
        alignSelf: 'center',
        marginTop: 12,
        overflow: 'hidden',
        borderRadius: 9,
        height: 12,
    },
    rail:
    {
        width: 150,
        backgroundColor: "#A5A7B4",
        height: '100%',
        zIndex: 1
    },
    selectedRail:
    {
        backgroundColor: "#DE1F41",
        height: '100%',
        zIndex: 3,
        position: 'absolute',
        top: 0,

    },
});
