import React, { Component } from 'react';
import { View, StyleSheet, Platform, Text, Image, TouchableOpacity } from 'react-native';
import { News } from "../assets/icons";
class NewsItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemHeigth: 120,
            boxWith: 0
        }
    }


    render() {
        return (
            <>
                {this.props.lastOne ?
                    <View dataSet={{ item: 'boxBefore' }} style={[styles.lastOne, { height: this.props.rightSize + (this.props.rightSize / 6) }]}>
                        <View style={[styles.coverBG]}>
                            <News size={50} color={'#DE1F41'} />
                        </View>
                        <Image
                            style={[styles.img]}
                            source={{ uri: this.props.img }}
                        />
                        <View style={[styles.contentViewLast]}>
                            <View style={[styles.radiusTextHolder,!this.props.img && styles.blackBg]}>
                                <Text style={styles.radiusText}>DERNIÈRE ACTUALITÉ</Text>
                            </View>
                            <Text style={[styles.contentTitre, styles.contentTitreLast,]} numberOfLines={2}>
                                {this.props.titre}
                            </Text>
                        </View>
                        <TouchableOpacity style={styles.able} onPress={this.props.onPress}>

                        </TouchableOpacity>
                    </View>
                    : <View dataSet={{ item: 'parentBoxBefore' }} style={[styles.box, { width: this.props.rightSize, }]}>

                        <View onLayout={(event) => {
                            this.setState({ itemHeigth: event.nativeEvent.layout.width - (event.nativeEvent.layout.width / 6) });
                        }}
                            style={[styles.imageView, { height: this.state.itemHeigth }]}
                            dataSet={{ item: 'boxBefore' }} >
                            <View style={styles.coverBG}>
                                <News size={50} color={'#DE1F41'} />
                            </View>
                            <Image
                                style={[styles.img]}
                                source={{ uri: this.props.img }}
                            />
                        </View>
                        <View style={[styles.contentView]}>
                            <Text style={[styles.contentTitre]} numberOfLines={1}>
                                {this.props.titre}
                            </Text>
                        </View>
                        <TouchableOpacity style={styles.able} onPress={this.props.onPress}>

                        </TouchableOpacity>
                    </View>}
            </>
        );
    }
};
const styles = StyleSheet.create({
    box:
    {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        marginBottom: 30,
    },
    lastOne:
    {
        width: "100%",
        borderRadius: 10,
        overflow: 'hidden',
        backgroundColor: '#FFE8E8'
    },

    imageView:
    {
        width: "100%",
        overflow: "hidden",
        borderRadius: 10,
        backgroundColor: "#FFE8E8"
    },

    img:
    {
        width: "100%",
        height: "100%",
        resizeMode: "cover"
    },
    contentView:
    {
        paddingTop: 10,
        width: "100%"
    },
    contentTitre:
    {
        fontWeight: "normal",
        color: "#1E202C",
        fontSize: 13,
        paddingLeft: 10,
        paddingRight: 10
    },
    contentViewLast:
    {
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
        backgroundColor: "#00000012",
        zIndex: 1,
        height: '100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        paddingTop:30,
        paddingLeft:15

    },
    contentTitreLast:
    {
        fontSize: 14,
        fontWeight:'bold',
        paddingLeft: 0,
        paddingTop:20,
        color:'#fff',
        maxWidth:450
    },
    able:
    {
        width: "100%",
        height: "100%",
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 100
    },
    coverBG:
    {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center"
    },
    radiusTextHolder:{
        backgroundColor:'#ffffffa3',
        height:18,
        borderRadius:9,
        paddingLeft:15,
        paddingRight:15,
        alignSelf:'baseline',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row'
    },
    blackBg:{
        backgroundColor:'#000000a3',
    },
    radiusText:{
        fontSize:9,
        color:'#fff'
    }
});

export { NewsItem }