
import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity, Text,} from 'react-native';
import { connect } from "react-redux";
import { Mic, Live, News,UserIcon } from "../assets/icons";
import { switchTrack } from "../Dev";
import OverLay from "../common/OverLay";
import Options from "../components/Options";
import { activePlayer, setTrackState,setCurrentTrack } from "../actions";
import TrackPlayer from "../services/TrackPlayer";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOverLay: false,
         }
   
    }
    hideOverlay() { this.setState({ showOverLay: false }); }
    refreshLive() {
        if ((this.props.currentTrack.id != -2)) {
            if (this.props.liveTrack) {
                var currentTrack = this.props.currentTrack;
                const track = this.props.liveTrack;
                if (track) {
                    currentTrack = {
                        id: track.id,
                        url: track.url,
                        title: track.title,
                        artist: track.artist,
                        artwork: track.artwork,
                        duration: track.duration,
                        isLive: true,
                        started_at: track.started_at,
                        end_at: track.end_at,
                    }
                    this.props.setTrackState(false);
                    this.props.setCurrentTrack(currentTrack);
                    this.props.activePlayer(2, false);
                    switchTrack('', [this.props.liveTrack], false, true);
                }
            }
            else {
                if (this.props.trackState) {
                    TrackPlayer.stop();
                }

                this.props.setTrackState(false);
                this.props.setCurrentTrack({
                    id: -2,
                    url: '',
                    title: '',
                    artist: '',
                    artwork: null,
                    duration: '',
                    isLive: true,
                    started_at: '',
                    end_at: '',
                });
                

            }
        }

    }
    render() {
        return (
            <>
                {this.props.user && <View style={[styles.Footer, { width: this.props.dimensions.width - 20 }]}>
                    <View style={styles.FooterBody}>
                        <View dataSet={{ item: 'primaryBG' }} style={[styles.FooterOption]}>
                            <TouchableOpacity style={styles.able} activeOpacity={0.9}
                                onPress={() => {
                                    this.props.navigation.navigate((this.props.client&&this.props.client.count_playlists===1)?'Podcasts':'PlayLists');
                                }}
                            >
                                <Mic color={this.props.active == 1 ? '#DE1F41' : '#A5A7B4'} />
                                <Text style={[styles.buttonText, this.props.active == 1 && styles.buttonTextActive]}>Podcasts</Text>
                            </TouchableOpacity>
                        </View>
                        <View dataSet={{ item: 'primaryBG' }} style={[styles.FooterOption]}>
                            <TouchableOpacity style={styles.able} activeOpacity={0.9}
                                onPress={() => {
                                    this.props.navigation.navigate('GPlayer');
                                    this.refreshLive();
                                }}
                            >
                                <Live color={this.props.active == 2 ? '#DE1F41' : '#A5A7B4'} />
                                <Text style={[styles.buttonText, this.props.active == 2 && styles.buttonTextActive]}>Live</Text>
                            </TouchableOpacity>
                        </View>
                        <View dataSet={{ item: 'primaryBG' }} style={[styles.FooterOption]}>
                            <TouchableOpacity style={styles.able}
                                onPress={() => {
                                    this.props.navigation.navigate('News');
                                }} activeOpacity={0.9}>
                                <News color={this.props.active == 3 ? '#DE1F41' : '#A5A7B4'} />
                                <Text style={[styles.buttonText, this.props.active == 3 && styles.buttonTextActive]}>Actualités</Text>
                            </TouchableOpacity>
                        </View>
                        <View dataSet={{ item: 'primaryBG' }} style={[styles.FooterOption]}>
                            <TouchableOpacity style={styles.able}
                                onPress={() => {
                                    this.setState({ showOverLay: true });
                                }} 
                                activeOpacity={0.9}>
                                <UserIcon color={this.props.active == 4 ? '#DE1F41' : '#A5A7B4'} />
                                <Text style={[styles.buttonText, this.props.active == 4 && styles.buttonTextActive]}>Profil</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>}
                <OverLay isVisible={this.state.showOverLay} onOverlayPress={() => { this.hideOverlay(); }}>
                     <Options active={this.props.activeOption ? this.props.activeOption : 0} navigation={this.props.navigation} onPress={() => { this.hideOverlay(); }} />
                </OverLay>
            </>
        );
    }

};
const styles = StyleSheet.create({
    Footer:
    {
        height: 60,
        backgroundColor: '#FFFFFF',
        borderRadius: 15,
        paddingRight: 20,
        paddingLeft: 20,
        marginBottom: 10,
        alignSelf: 'center',
        borderWidth: 3,
        borderColor: '#ebebeb63',
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    FooterBody: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: '100%',
        maxWidth:550,
        flex:1
    },
    FooterOption:
    {
        height: 36,
        flex: 1,
        overflow: "hidden"
    },
    ActiveFooterOption:
    {
        backgroundColor: "#DE1F41"
    },
    able:
    {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'column'
    },
    buttonText:
    {
        fontSize: 10,
        fontWeight: 'bold',
        color: "#A5A7B4",
        paddingTop: 5,
    },
    buttonTextActive: {
        color: "#DE1F41",
    }

});
const mapStateToProps = state => {
    return {
        liveTrack: state.liveTrack.liveTrack,
        currentTrack: state.track_current.currentTrack,
        trackState: state.track_state.trackStatus,
        user: state.user.user,
        client: state.client.client,
        dimensions: state.dimensions.dimensions,
    }
}
export default connect(mapStateToProps, { activePlayer, setTrackState,setCurrentTrack})(Footer);