
import React from 'react';
import { Dimensions, Animated, Platform, Text } from 'react-native';
import TrackPlayer,{ Capability } from './services/TrackPlayer';
import { StackActions, NavigationActions } from 'react-navigation';
import styleG from './assets/style/styleG';
export const dimensions = { width: Platform.OS === 'web' ? innerWidth : Dimensions.get('window').width, height: Platform.OS === 'web' ? innerHeight : Dimensions.get('window').height }
export const apiUrl = 'https://admin.radiofactory.ma/api/';
import { Live, Mic, News, Privacy, Setting } from './assets/icons';

export const slidePanel = (value_, toValue_, time_delay, fb_back = () => { }) => {
  Animated.parallel([
    Animated.spring(value_, {
      toValue: toValue_,
      duration: time_delay,
      tension: 16,
      friction: 15,
      useNativeDriver: false,
    }).start(() => {
      fb_back();
    })
  ]).start();
}
/* export const cryptLock = (value_) => {
  var cryptedString = '', reverse = '', from_ = Math.floor(Math.random() * value_.length);
  console.log(from_);
} */
export const currentScreen = (screen) => {

  switch (screen) {
    case 'Live':
      return <>
        <Live color='#DE1F41' />
        <Text style={styleG.screenTitle}>{screen}</Text>
      </>
    case 'Podcasts':
      return <>
        <Mic color='#DE1F41' />
        <Text style={styleG.screenTitle}>{screen}</Text>
      </>
    case 'Actualités':
      return <>
        <News color='#DE1F41' />
        <Text style={styleG.screenTitle}>{screen}</Text>
      </>
    case 'Mentions légales':
      return <>
        <Privacy size={20} color='#DE1F41' />
        <Text style={styleG.screenTitle}>{screen}</Text>
      </>
    case 'Mon profil':
      return <>
        <Setting size={20} color='#DE1F41' />
        <Text style={styleG.screenTitle}>{screen}</Text>
      </>
    default:
      return null;
  }
}

export const passwordChecker = (val) => {
  let result = '';
  if (/[a-z]/.test(val)) result += '1';
  if (/[A-Z]/.test(val)) result += '2';
  if (/[1-9]/.test(val)) result += '3';
  if (/[!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?]/.test(val)) result += '4';
  if (val.length >= 8) result += '5';
  return result;
}
export const validEmail = (email) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  return !reg.test(email) ? false : true;
}
export const formatDuration = (value_) => {
  var min_, s_, time_ = '';
  if (value_ > 0) {
    min_ = parseInt(value_ / 60);
    min_ > 9 ? time_ = min_ : time_ = '0' + min_;
    s_ = parseInt(value_ % 60);
    s_ > 9 ? time_ += ':' + s_ : time_ += ':0' + s_
  }
  else {
    time_ = '00:00';
  }
  return time_
}
export const validateInput = (val, type = 'text', length = 1) => {
  switch (type) {
    case 'password':
      return val.length >= length ? true : false;
    case 'email':
      return validEmail(val);
    default:
      return val.length >= length ? true : false;
  }
}
export const stopUser = (props_, error_ = '') => {
  if (error_ === 'Token invalide')
    props_.dispatch(resetLogin);
}

export const switchTrack = async (id = '', tracks, islive = false, fromFooter = false) => {

  if (tracks.length) {
    if (Platform.OS != 'web') {
      const liveCom = [
        Capability.Pause,
        Capability.Play,
      ];
      const playerCom = [
        Capability.Pause,
        Capability.SeekTo,
        Capability.SkipToNext,
        Capability.SkipToPrevious,
        Capability.Play,
      ]
      let queue = await TrackPlayer.getQueue();
      if (queue.length === 0 || id === '' || islive || id != -2) {
        await TrackPlayer.stop();
        await TrackPlayer.reset();
        await TrackPlayer.add(tracks);
        TrackPlayer.updateOptions({
          stopWithApp: Platform.OS==='ios'?false:true,
          capabilities: id === '' ? liveCom : playerCom,
          compactCapabilities: id === '' ? liveCom : playerCom,
          playIcon: require('./assets/img/play.png'),
          pauseIcon: require('./assets/img/pause.png'),
          previousIcon: require('./assets/img/previous.png'),
          nextIcon: require('./assets/img/next.png'),
          icon: require('./assets/img/appIcon.png')
        });
      }
      let getpos = id === '' ? tracks.findIndex(el => el.id === -2) : id;
      await TrackPlayer.skip(getpos);
      if (!fromFooter)
          TrackPlayer.play();
    } else {
      const track = tracks.find(element => element.id === (id === '' ? -2 : id));
      if (track) {
        if (!TrackPlayer.isUrlSet()) {
          TrackPlayer.load(track.url).then(() => {
            TrackPlayer.play();
            if (fromFooter)
              TrackPlayer.pause();
          });
        }
        else {
          TrackPlayer.play(track.url);
          if (fromFooter)
            TrackPlayer.pause();
        }
      }
    }
  }
}
export const noAvatar = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/2wBDAQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wAARCACgAKADASIAAhEBAxEB/8QAGwABAAIDAQEAAAAAAAAAAAAAAAEFAwQGAgf/xAAuEAEAAgECBQIFAgcAAAAAAAAAAQIDBBEFEiExQVFSEyJhcbEyMxRCYnKBocH/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/APswAAAAAAAAAAAAAAAAAAAAAAAAAA82tWlZtadojrMyp9XxC+eeSm9Kf7kFhn1+DBO025relerUvxi0/oxRH3lXCosK8YyR+rFWftOzYxcUwZJ2vE0n69lOA6WtotG9ZiYnzCXP6bVZNNfes7181ntK8wZqajFGSk9J8eiKyAAAAAAAAAAAi1orWbT2iNwVXFdRzXjBWeletvur03vN8lrz3tO8oVAAAEAlucN1HwdRyT+nJ0/y0yJmJ3jvAOlS8Y7/ABMVL+6Il7RQAAAAAAABh1k7aTLMe2WZh1VefS5ax7ZBz4CoAAAAAAv9FO+jxf2s7Dpa8mlxV/phmRQAAAAAAABi1OamDDa9+3aI9WVocXif4ek+lv8AgKhCRUEJAQkAQkAdBpdRTUYuam+0dJ3ZmlwqvLpN/daZbqKAAAAAAAAMOpwxqMFsfme33ZgHNWralpraNpjvCHQajT0z4rVmI5pjpbZQWpNLzW0bTE7TCogAECQB7xYMma0VpWZ3nvt0RTHbJkilY3m07Q6DDijDhrjj+WATixxixVxx2rGz2CKAAAAAAAAAAKji9YjNS0R1mvVbqrjH7mL7SCuECokAFhwiInLkmY6xHRbKrg/7mX7QtUUAAAAAAAAAAETaKxvaYiI8y0s/FMOPpjj4k/TsDd7KXiOorn1HyTvWkbb+rHqNbm1HS1uWvtr2a6iQQIkQkG3w7UVwaj552reNt/RdOaZ9Prc2n6VtzV9tgX40cHFMOTaMkfDn69m7FotG9ZiYnzCKkAAAAGjquJUwzNMcRe3r4gG5e9cdea9orEeZloZ+LVj5cNeafWeytzZ8me3NktM/iHhUZM2fLntvkvM/TwxgAAAAAAAAAyYs+XBbfHeY+niWMBa4OLVnaM1eWfdHZv0vW9ealotE+Ylzb3iz5MFubHaY/Eoroxo6XiVM0xTJEUt6+JbwKviOtmLTgxzt7pj8K1N7Te9rT3md0KghICBIAAAhICEgCEgAAAhIAs+G62ZtGDJO/tmfwrE0tNL1tHeJ3B//2Q==='

export const podToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjgzYjNiMTcyMWJhNDcwMzVmYmNiOTI5NzlmOGM4NTY3NzZiNjBiNzFkODdkNDYzYWNmNDAxYTZlNzBiYzg5YTI5Yzc1NTdjNmIwNWI5OGZjIn0.eyJhdWQiOiIxIiwianRpIjoiODNiM2IxNzIxYmE0NzAzNWZiY2I5Mjk3OWY4Yzg1Njc3NmI2MGI3MWQ4N2Q0NjNhY2Y0MDFhNmU3MGJjODlhMjljNzU1N2M2YjA1Yjk4ZmMiLCJpYXQiOjE2MjA0MDMwNjMsIm5iZiI6MTYyMDQwMzA2MywiZXhwIjoxNjUxOTY3OTk5LCJzdWIiOiIxODQ0NiIsInNjb3BlcyI6WyJzaG93cy5pbmRleFdpdGhHcmFudGVkIiwic2hvd3Muc2hvdyIsInNob3dzLnNob3cuYnlfcHVibGljaWQiLCJwbGF5bGlzdHMuaW5kZXgiLCJwbGF5bGlzdHMuc3RvcmUiLCJwbGF5bGlzdHMuc2hvdyIsInBsYXlsaXN0cy51cGRhdGUiLCJwbGF5bGlzdHMuZGVzdHJveSIsInBsYXlsaXN0cy5wb2RjYXN0LnN0b3JlIiwicGxheWxpc3RzLnBvZGNhc3QuZGVzdHJveSIsInBsYXlsaXN0cy5wb2RjYXN0Lm1vdmUiLCJwbGF5bGlzdHMuaW1hZ2Uuc3RvcmUiLCJwbGF5bGlzdHMuaW1hZ2UuZGVzdHJveSIsInBvZGNhc3RzLnZpZGVvLmluZGV4IiwicG9kY2FzdHMudmlkZW8uc2hvdyIsInNlYXNvbnMuaW5kZXgiLCJzZWFzb25zLnN0b3JlIiwic2Vhc29ucy5zaG93Iiwic2Vhc29ucy5kZXN0cm95Iiwic2hvd3MucG9kY2FzdHMuc2Vhc29uLnVwZGF0ZSIsImNhbXBhaWducy5pbmRleCIsImNhbXBhaWducy5zaG93IiwicG9kY2FzdHMuaW5kZXgiLCJwb2RjYXN0cy5zaG93IiwicG9kY2FzdHMuc2hvdy5ieV9wdWJsaWNpZCIsInBvZGNhc3RzLnN0b3JlIiwicG9kY2FzdHMudXBkYXRlIiwicG9kY2FzdHMuZGVzdHJveSIsInBvZGNhc3RzLmltYWdlLnN0b3JlIiwicG9kY2FzdHMuZmlsZS5zdG9yZSJdfQ.aWZ1ryecPIs1xw_Mr7Qfle7SXT3HM-2xuoM3hzObhtz6qtil_-qX8TzNJvQM364fKBQAj8j3bDGoESyLCspoaw1Rcc4edUGC6qBwoKCl5KSRrXH4QBkfElncqIecVNFn-YKzZO1EebcTln8B3QNenDstv_HCkP6dNPuJ6vXegzeUveHcwkLeRR-8GpOXUl_IXENibKKOtH9cRmOoTRdhBnKR9TYa3JtEbbp0HxXLrP5n71XvPeXoFwPhDYC-_Ec7Lwjnb0q4KjInOBRQPk6fqXog7w0WPYo0qhVmW5_S4ASvUGdU1bJ3alYE3g2x0QE4PDJc2wYhblmJGrFBhq2aSqiPncG6_DhbIqRZxSSB4go2FMcQ3s9Cx37GllXRri8lWYmOq0UYZTg4opf_Um_HUSNl20Qk8ZNs_LLnUGRKidnXSPmjiqTtzjK2sIhrPsOeN2GAKYReUP6-GcfpYl7ZTcKURH5Nk9Aup7p0sN1tUe9Al-MewSFLcNEG9YhWVU8Vm-Od84i-T_VnS0G7nEdaItIW_WlTf73GaEYQkdACZsdPVcnzcq6zW-Lh7dhcTYK_hetAWJNMQUEPyAo8TYWuy7Od_x_ZI4nmiTZeETBg3sauLzlEZ62NateEzFA9iTPG-B1Zr11KLlokDZNN02VAdvZeNu-DG0pZ1ozpfxtpOvI';


export const resetLive = StackActions.reset({
  index: 0,
  actions: [NavigationActions.navigate({ routeName: 'GPlayer' })],
});
export const resetLogin = StackActions.reset({
  index: 0,
  actions: [NavigationActions.navigate({ routeName: 'Login' })],
});
export const resetRegister = StackActions.reset({
  index: 0,
  actions: [NavigationActions.navigate({ routeName: 'Register' })],
});
export const ResetPassWord = StackActions.reset({
  index: 0,
  actions: [NavigationActions.navigate({ routeName: 'ResetPassWord' })],
});
export const privacyPolicy = StackActions.reset({
  index: 0,
  actions: [NavigationActions.navigate({ routeName: 'Privacy' })],
});

export const  resetHelp = StackActions.reset({
  index: 0,
  actions: [NavigationActions.navigate({ routeName: 'Help' })],
});

export const HelpHtml = `<div>
<p ><span>Ecoutez vos PODCASTS d’entreprise partout où vous êtes, depuis votre Smartphone ou votre Tablette et ce, grâce à l’application Web Radio by Radio Factory, qui vous assure un accès simple et rapide à tous vos PODCASTS et à votre WEBRADIO d’entreprise.</span></p>
<p ><b><span>Comment ça marche&nbsp;?</span></b></p>
<p ><span style="font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;
</span></span></span><span>&nbsp;Téléchargez gratuitement
l’application sur Google Play et App Store.</span></p>
<p><span style="font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-bidi-font-weight:bold"><span style="mso-list:Ignore">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span></span><span>Accédez à vos comptes en saisissant votre email et le mot de passe de votre
compte reçu par <b>email</b></span></p>
<p ><b><span>Comment réinitialiser mon mot de
passe&nbsp;?</span></b></p>
<p ><span>Ouvrez l’application et cliquez sur le lien
«&nbsp;mot de passe oublié&nbsp;», saisissez votre email, si votre compté
existe vous allez recevoir les instructions à suivre par email.</span></p>
<p ><b><span>L’application ne fonctionne pas sur mon
téléphone&nbsp;</span></b></p>
<p ><span>Si vous avez des difficultés à utiliser
l’application contactez le support par email <a href="mailto:support@radiofactory.ma">support@radiofactory.ma</a></span></p>
<p ><span><span style="mso-spacerun:yes">&nbsp;</span></span></p>
</div>`