import {DIMENSIONS} from "../actions/Types";
import { dimensions,} from "../Dev";


const INTIAL_STATE=
{
    dimensions:{
        width:dimensions.width,
        height:dimensions.height
    },
}

export default (state=INTIAL_STATE,action)=>{

    switch (action.type) {
        case DIMENSIONS:
            return {...INTIAL_STATE,dimensions:action.dimensions}
        default:
            return state;
    }
}