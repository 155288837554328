import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const Privacy = (props) => {
    return (
        <View>
            <Svg width={props.size?props.size:14} height={props.size?props.size:14} viewBox="0 0 14 14">
                <Path d="M11.952,2.051a7,7,0,1,0,0,9.9A7.011,7.011,0,0,0,11.952,2.051ZM7,11.672a.584.584,0,1,1,.584-.584A.584.584,0,0,1,7,11.672Zm.584-2.918a.584.584,0,1,1-1.167,0V2.917a.584.584,0,1,1,1.167,0Z" 
                 transform="translate(0 -0.003)" fill={props.color?props.color:'#fff'} />

            </Svg>
        </View>
    );
};
export { Privacy }