
import React, { Component } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { resetLogin, apiUrl,validateInput} from "../Dev";
import styleG from "../assets/style/styleG";
import { connect } from "react-redux";
import { FlatInput, Buttons } from '../common';
import { setDimensions } from "../actions";
import { Loader } from '../components';
import axios from 'axios';
import MessageBox from '../common/MessageBox';
class ResetPassWord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listHeigh: 180,
            new_password: "",
            confirm_password: "",
            token_to_reset: "",
            loader: true,
            showBoxMsg: false,
            boxMsg: "",
            boxColor: '#28a745',
            disabledbtn:false,
            loadingbtn:false,
            passChanged:false
        };
    }
    componentDidMount() {
        if (Platform.OS == "web") {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            if (token) {
                this.setState({token_to_reset:token},()=>{
                    this.resetPassword_(true);
                })
            } else {
                this.props.navigation.dispatch(resetLogin);
            }
        }
    }

    form_validation(form_="") {
      
           if (validateInput(this.state.new_password, 'password', 8) && validateInput(this.state.confirm_password, 'password', 8)) {
               this.setState({ disabledbtn: false });
               return false;
           }
           this.setState({ disabledbtn: true });
      
   }
    resetPassword_(check_token = false) {
        this.setState({ loadingbtn: true });
        let formData = new FormData();
        formData.append('password', this.state.new_password);
        formData.append('password_confirm', this.state.confirm_password);
        formData.append('token', this.state.token_to_reset);
        if (check_token)
            formData.append('check_token', 'yes');
        axios.post(apiUrl + 'reset-password', formData)
            .then(res => {
                if (res.data.status == 'error') {
                    this.setState({ boxColor: '#dc3545', boxMsg: res.data.messages.join('\n') }, () => {
                        this.setState({ showBoxMsg: true });
                    });
                    this.setState({ loader: false });
                    this.setState({ loadingbtn: false });
                }
                else if (res.data.status == 'success') {
                    !check_token&&this.setState({ boxColor: '#28a745', boxMsg: res.data.messages.join('\n') }, () => {
                        this.setState({ showBoxMsg: true });
                    });
                    this.setState({ loader: false,loadingbtn: false});
                    !check_token&&this.setState({passChanged:true});
                }
            })
            .catch( (error) =>{
                if(error.message=='Network Error')
                {
                    this.setState({ boxColor: '#dc3545', boxMsg: 'Erreur réseau'}, () => {
                        this.setState({ showBoxMsg: true });
                    });
                }
                else
                {
                    this.props.navigation.dispatch(resetLogin);
                }
                this.setState({ loadingbtn: false });
            });
    }

    render() {
        return (
            <>
                <MessageBox bg={this.state.boxColor} msg={this.state.boxMsg} show={this.state.showBoxMsg} closeBox={() => {
                    this.setState({ showBoxMsg: false },()=>{
                        if(this.state.passChanged)
                           this.props.navigation.dispatch(resetLogin);
                    });
                }} />
                {!this.state.loader ?
                    <View
                        style={[styleG.container_, { alignItems: 'center', justifyContent: "center" }]}>
                        <View style={styles.viewForm}>
                            <View style={[styles.form_, styles.form_radius]}>

                                <FlatInput
                                    placeholder='nouveau mot de passe'
                                    secureTextEntry={true}
                                    onChangeText={(new_password) => {
                                        this.setState({ new_password })
                                    }}
                                    value={this.state.new_password}
                                    onSubmitEditing={() => { }}
                                    containerStyle={{ borderBottomColor: '#BEBEBE' }}
                                    placeholderTextColor={'#989898'}
                                    style={{ color: "#181A20" }}
                                />
                                <FlatInput
                                    placeholder='Confirmez votre mot de passe'
                                    secureTextEntry={true}
                                    onChangeText={(confirm_password) => {
                                        this.setState({ confirm_password })
                                    }}
                                    value={this.state.confirm_password}
                                    onSubmitEditing={() => { }}
                                    containerStyle={{ borderBottomColor: '#BEBEBE' }}
                                    placeholderTextColor={'#989898'}
                                    style={{ color: "#181A20" }}
                                />
                                <View style={{ marginTop: 20 }}>
                                    <Buttons loading={this.state.loadingbtn} disabled={this.state.disabledbtn}
                                     onPress={() => { this.resetPassword_(); }} text={'CONFIRMER'} />
                                </View>
                            </View>
                        </View>
                    </View> :
                    <Loader />
                }
            </>
        );
    }
};

const styles = StyleSheet.create({

    selectAvatarBtn:
    {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 10,
    },

    contact: {
        paddingBottom: 7,
        paddingTop: 10,
        borderColor: "#DE1F41",
        borderBottomWidth: 1,
        color: "#fff",
        fontSize: 14,
        fontWeight: "500",
        alignSelf: "center"
    },
    viewForm:
    {
        maxWidth: 700,
        width: '90%',
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    form_: {
        backgroundColor: "#fff",
        padding: 20,
        overflow: "hidden",
        width: "100%",
    },
    form_radius:
    {
        borderRadius: 10
    },
});
const mapStateToProps = state => {
    return {
        dimensions: state.dimensions.dimensions,
    }
}
export default connect(mapStateToProps, { setDimensions })(ResetPassWord);