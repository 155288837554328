

import Svg, { Path } from 'react-native-svg';
import React from 'react';
import { View } from 'react-native';
const Eye = (props) => {
  return (
    <View>
      <Svg xmlns="http://www.w3.org/2000/svg" width="16.69" height="11.124" viewBox="0 0 16.69 11.124">
        {!props.show ? <>
          <Path d="M16.487,90.454l-2.9-2.9a7.414,7.414,0,0,0-10.474,0l-2.9,2.9a.7.7,0,0,0,0,.983l2.9,2.9a7.414,7.414,0,0,0,10.474,0l2.9-2.9A.7.7,0,0,0,16.487,90.454ZM8.345,94.423a3.477,3.477,0,1,1,3.477-3.477A3.481,3.481,0,0,1,8.345,94.423Z" transform="translate(0 -85.384)" fill={props.color?props.color:"#fff"} />
          <Path d="M194.091,192a2.086,2.086,0,1,0,2.086,2.086A2.089,2.089,0,0,0,194.091,192Z" transform="translate(-185.746 -188.528)" fill={props.color?props.color:"#fff"} />
        </> :
          <>
            <Path d="M156.891,142.676l-2.991-2.991c-.149-.149-.306-.285-.464-.42l-8.663,8.663a7.62,7.62,0,0,0,9.127-1.25l2.991-2.991A.715.715,0,0,0,156.891,142.676Zm-8.384,4.087a.716.716,0,0,1,0-1.432,2.151,2.151,0,0,0,2.148-2.148.716.716,0,1,1,1.432,0A3.585,3.585,0,0,1,148.507,146.763Z" transform="translate(-139.913 -137.459)" fill={props.color?props.color:"#fff"} />
            <Path d="M3.2,87.676.21,90.666a.715.715,0,0,0,0,1.013L3.2,94.669c.149.149.306.285.464.42l8.663-8.663A7.633,7.633,0,0,0,3.2,87.676Zm5.393,1.348a2.151,2.151,0,0,0-2.148,2.148.716.716,0,0,1-1.432,0,3.585,3.585,0,0,1,3.581-3.581.716.716,0,1,1,0,1.432Z" transform="translate(0 -85.449)" fill={props.color?props.color:"#fff"} />
          </>
        }
      </Svg>
    </View>
  );
};
export { Eye }