
import React, { Component } from 'react';
import { View, ScrollView, StyleSheet, Text, Platform } from 'react-native';
import styleG from "../assets/style/styleG";
import Footer from '../components/Footer';
import Header from '../components/Header';
import {connect} from 'react-redux';
import  HTMLView from '../components/HTMLView';
import {HelpHtml} from "../Dev";
import MessageBox from '../common/MessageBox';

class Help extends Component {
     constructor()
     {
         super();
         this.state=
         {
             title:'VOTRE RADIO D’ENTREPRISE DANS VOTRE POCHE',
             content:'',
             showBoxMsg: false,
             boxMsg: "",
             boxColor: '#28a745',
         }
     }
    render()
    {
        return (
            <>
             <MessageBox bg={this.state.boxColor} msg={this.state.boxMsg} show={this.state.showBoxMsg} closeBox={() => {
                    this.setState({ showBoxMsg: false });
                }} />
            <View style={[styleG.container_]}>
                {/* Header */}
                <Header activeOption={2} navigation={this.props.navigation} />
                {/* Header */}
                <View style={[styleG.container_]}>
                    <ScrollView style={[styles.Holder]}
                        stickyHeaderIndices={[0]}
                        showsVerticalScrollIndicator={true}
                    >
                        <View style={styles.titreHolder}>
                            <View style={[styleG.titreH4Border]}>
                                <Text style={[styleG.titreH4]}>{this.state.title}</Text>
                            </View>
                        </View>
    
                        <View style={styles.Content}>
                            <View style={styles.Desc}>
                            <HTMLView value={HelpHtml.replace(/\n/g,'')} stylesheet={stylesHtml}/> 
                       </View>
                        </View>
                    </ScrollView>
                </View>
    
                <Footer navigation={this.props.navigation} />
            </View>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        user:state.user.user
    }
}
export default connect(mapStateToProps, {})(Help);

const styles = StyleSheet.create({
    Holder:
    {
        width: "100%",
        flex: 1,
        maxWidth: Platform.OS == "web" ? 700 : "100%",
        alignSelf: "center"
    },
    Content:
    {
        paddingBottom: 15,
        marginRight: 30,
        marginLeft: 30
    },
    titreHolder:
    {
        marginRight: 30,
        marginLeft: 30,
        paddingTop: 15,
        backgroundColor: '#FAF9FC',
        paddingBottom:20
    },
    Desc:
    {
        marginTop: 10
    },
});
const stylesHtml = StyleSheet.create({
   p:{
       marginTop:0,
       padding:0
   }
});