import {combineReducers} from "redux";

import InfosRed from "./InfosRed";
import TracksRed from "./TracksRed";
import TrackStateRed from "./TrackStateRed";
import ActivePlayer from "./ActivePlayer";
import Dimensions from "./Dimensions"
import UserRed from './UserRed';
import ClientRed from './ClientRed';
import LiveTrackRed from './LiveTrackRed';
import SecretRed from './SecretRed';
import CurrentPlayList from './CurrentPlayList';
import TrackCurrentRed from './TrackCurrentRed';
export default combineReducers({
     infos : InfosRed,
     tracks : TracksRed,
     track_state : TrackStateRed,
     activePlayer: ActivePlayer,
     dimensions: Dimensions,
     user: UserRed,
     client: ClientRed,
     liveTrack: LiveTrackRed,
     secret: SecretRed,
     playList:CurrentPlayList,
     track_current:TrackCurrentRed
    });