
import React, { Component } from 'react';
import { View, Text, Animated, StyleSheet,Platform} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import { Check } from "../assets/icons";
import { slidePanel } from "../Dev";
class MessageBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBox: false,
            animeBox: new Animated.Value(0),
        };
    }
    toggleBox() {
        this.props.show ? this.setState({ showBox: true }, function () { slidePanel(this.state.animeBox, 1, 300) }) : slidePanel(this.state.animeBox, 0, 300, () => {
            this.setState({ showBox: false });
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.toggleBox();
        }
    }
    render() {
        const interpolate = this.state.animeBox.interpolate({
            inputRange: [0, 1],
            outputRange: [200, 0]
        })
        return (
            <>
                {this.state.showBox && <View style={[styles.boxContainer, {
                    width: this.props.dimensions.width,
                    height: this.props.dimensions.height,
                }]}>
                    <Animated.View style={[styles.box,
                    {
                        opacity: this.state.animeBox, top: interpolate,
                        backgroundColor: this.props.bg ? this.props.bg : '#28a745',
                    }]}>
                        <Text style={styles.msg}>{this.props.msg}</Text>

                        <View style={styles.closeBox}>
                            <TouchableOpacity onPress={() => {
                                this.props.closeBox();
                            }} style={styles.able} >
                                <Check times />
                            </TouchableOpacity>
                        </View>
                    </Animated.View>
                    <View style={styles.touchOver}>
                        <TouchableOpacity style={styles.ablefull} onPress={() => {
                            this.props.closeBox();
                        }}>

                        </TouchableOpacity>
                    </View>
                </View>}
            </>
        );
    }
};

const styles = StyleSheet.create({
    box:
    {
        maxWidth: 200,
        maxHeight: 250,
        borderRadius: 10,
        padding: 20,
        zIndex: 3
    },
    boxContainer:
    {
        position: Platform.OS == 'web' ? "fixed" : "absolute",
        top: 0,
        right: 0,
        backgroundColor: "#0000003d",
        zIndex: 99999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        width:'100%',
        height:'100%'
    },
    closeBox:
    {
        width: 26,
        height: 26,
        backgroundColor: '#6c757dc9',
        borderRadius: 13,
        overflow: 'hidden',
        position: 'absolute',
        right: -10,
        top: -10
    },
    able:
    {
        width: 26,
        height: 26,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
    },
    ablefull:
    {
        width: Platform.OS == 'web' ?'100vw':'100%',
        height: Platform.OS == 'web' ?'100vh':'100%',
    },
    msg: {
        color: '#fff',
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "left"
    },
    touchOver:
    {
        position: "absolute",
        height: "100%",
        width: "100%",
        left: 0,
        top: 0,
        zIndex: 2,
    }
});
const mapStateToProps = state => {
    return {
        dimensions: state.dimensions.dimensions,
    }
}

export default connect(mapStateToProps, {})(MessageBox);