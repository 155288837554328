import React, {Component} from 'react';
import {
  Image,
  View,
  StyleSheet,
  Text,
  Platform,
  TouchableOpacity,
  Animated,
  StatusBar,
  KeyboardAvoidingView,
} from 'react-native';

import {apiUrl, slidePanel, validateInput, resetLive} from '../Dev';
import styleG from '../assets/style/styleG';
import {RadioLogo} from '../assets/icons';
import {FlatInput, CheckBox, Buttons} from '../common';
import MessageBox from '../common/MessageBox';
import {connect} from 'react-redux';
import {
  setUser,
  setDimensions,
  clientUser,
  setSecrets,
  setTracks,
  setLiveTrack,
  setPlaylist,
  setTrackState,
  setCurrentTrack,
  activePlayer,
} from '../actions';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import loginMd from '../assets/img/loginMd.png';
import loginLg from '../assets/img/loginMd.png';
import loginSm from '../assets/img/loginSm.jpg';
class Login extends Component {
  constructor() {
    super();
    this.state = {
      clickType: false,
      ViewPassword: new Animated.Value(0),
      ViewLogin: new Animated.Value(160),
      inputanime: new Animated.Value(0),
      email: '',
      email_reset: '',
      password: '',
      remember: false,
      disabledLogin: false,
      disabledresetPassword: false,
      loadingLogin: false,
      loadingresetPassword: false,
      showBoxMsg: false,
      boxMsg: '',
      boxColor: '#28a745',
      showOverflow: false,
    };
  }

  componentDidMount() {
    StatusBar.setBarStyle('light-content');
    this.checkStorage('get');
    this.reset_();
  }
  reset_() {
    this.props.setUser(null);
    this.props.clientUser(null);
    this.props.setSecrets(null);
    this.props.setTracks([]);
    this.props.setPlaylist(null);
    this.props.setLiveTrack(null);
    this.props.setCurrentTrack({
      id: -2,
      url: '',
      title: 'UNKNOWN',
      artist: 'UNKNOWN',
      artwork: null,
      duration: 1,
      isLive: true,
      started_at: '',
      end_at: '',
  },);
  this.props.activePlayer(2, false);
  }
  async checkStorage(action = 'set', userToken = '') {
    var email, password, remember;
    switch (action) {
      case 'get':
        try {
          remember = await AsyncStorage.getItem('@remember');
          this.setState(
            {remember: remember == '1' ? true : false},
            async () => {
              if (this.state.remember) {
                email = await AsyncStorage.getItem('@email');
                password = await AsyncStorage.getItem('@password');
                this.setState({
                  email: email ? email : '',
                  password: password ? password : '',
                });
              }
            },
          );
        } catch (e) {}
        break;
      default:
        try {
          await AsyncStorage.setItem('@email', this.state.email);
          await AsyncStorage.setItem('@password', this.state.password);
          await AsyncStorage.setItem(
            '@remember',
            this.state.remember ? '1' : '0',
          );
          await AsyncStorage.setItem('@token', userToken);
        } catch (e) {}
        break;
    }
  }
  forgetPassWord() {
    let formData = new FormData();
    this.setState({loadingresetPassword: true});
    formData.append('email', this.state.email);
    axios
      .post(apiUrl + 'forget-password', formData)
      .then(res => {
        if (res.data.status == 'error') {
          this.setState(
            {boxColor: '#dc3545', boxMsg: res.data.messages.join('\n')},
            () => {
              this.setState({showBoxMsg: true});
            },
          );
          this.setState({loadingresetPassword: false});
        } else if (res.data.status == 'success') {
          this.setState(
            {boxColor: '#28a745', boxMsg: res.data.messages.join('\n')},
            () => {
              this.setState({showBoxMsg: true});
            },
          );
          this.setState({loadingresetPassword: false});
        }
      })
      .catch(function (error) {
        this.setState({loadingresetPassword: false});
      });
  }
  form_validation(form_ = '') {
    if (form_ == 'resetpasword') {
      if (validateInput(this.state.email_reset, 'email')) {
        this.setState({disabledresetPassword: false});
        return false;
      }
      this.setState({disabledresetPassword: true});
    } else {
      if (
        validateInput(this.state.password, 'password', 8) &&
        validateInput(this.state.email, 'email')
      ) {
        this.setState({disabledLogin: false});
        return false;
      }
      this.setState({disabledLogin: true});
    }
  }
  CheckInfos_() {
    let formData = new FormData();
    this.setState({loadingLogin: true});
    formData.append('email', this.state.email);
    formData.append('password', this.state.password);

    axios
      .post(apiUrl + 'login', formData, {
        headers: {Authorization: 'Basic cmZjYWRtaW46UEBzc2VSQw=='},
      })
      .then(response => {
        if (response.data.status == 'error') {
          this.setState(
            {boxColor: '#dc3545', boxMsg: response.data.messages.join('\n')},
            () => {
              this.setState({showBoxMsg: true});
            },
          );
          this.setState({loadingLogin: false});
        } else if (response.data.status == 'success') {
          this.props.setUser(response.data.user);
          this.props.clientUser(response.data.client);
          this.props.setSecrets(response.data.secrets);
          this.checkStorage('', response.data.secrets.access_token);
          this.setState({loadingLogin: false}, () => {
            this.props.navigation.dispatch(resetLive);
          });
        }
      })
      .catch(error => {
        if (error.message == 'Network Error') {
          this.setState({boxColor: '#dc3545', boxMsg: 'Erreur réseau'}, () => {
            this.setState({showBoxMsg: true});
          });
        }
        this.setState({loadingLogin: false});
      });
  }
  render() {
    const interpo = this.state.inputanime.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 0],
    });
    return (
      <>
        <MessageBox
          bg={this.state.boxColor}
          msg={this.state.boxMsg}
          show={this.state.showBoxMsg}
          closeBox={() => {
            this.setState({showBoxMsg: false});
          }}
        />
        <View
          style={[styleG.container_, {justifyContent: 'center'}]}
          onLayout={() => {
            if (Platform.OS == 'web') {
              this.props.setDimensions();
            }
          }}>
          <Image
            style={[
              styles.loginBg,
              {
                width: this.props.dimensions.width,
                height: this.props.dimensions.height,
              },
            ]}
            source={
              this.props.dimensions.width < 500
                ? loginSm
                : this.props.dimensions.width > 500 &&
                  this.props.dimensions.width < 650
                ? loginMd
                : loginLg
            }
          />
          <KeyboardAvoidingView
            behavior={'padding'}
            style={[
              styles.loginForm,
              {width: this.props.dimensions.width - 30},
            ]}>
              <View style={styles.logoHolder}>
                <RadioLogo size={40}/>
              </View>
            <View>
              <Animated.View
                style={{
                  overflow: 'hidden',
                  width: '100%',
                  height: this.state.ViewLogin,
                  opacity: interpo,
                }}>
                <FlatInput
                  placeholder="Adresse E-mail"
                  secureTextEntry={false}
                  onChangeText={email => {
                    this.setState({email}, () => {
                      this.form_validation();
                    });
                  }}
                  onSubmitEditing={() => {}}
                  value={this.state.email}></FlatInput>
                <FlatInput
                  placeholder="Mot de passe"
                  secureTextEntry={true}
                  onChangeText={password => {
                    this.setState({password}, () => {
                      this.form_validation();
                    });
                  }}
                  onSubmitEditing={() => {}}
                  value={this.state.password}></FlatInput>

                <View style={styles.loginInfo}>
                  <CheckBox
                    value={this.state.remember}
                    label="Se souvenir de moi."
                    onChange={remember => {
                      this.setState({remember});
                    }}
                  />
                  <TouchableOpacity
                    activeOpacity={0.9}
                    onPress={() => {
                      this.setState({clickType: true}, function () {
                        slidePanel(this.state.ViewPassword, 175, 500, () => {
                          this.setState({showOverflow: true});
                        });
                        slidePanel(this.state.ViewLogin, 0, 500);
                      });
                    }}>
                    <Text style={styles.textUnderline}>
                      Mot de passe oublié?
                    </Text>
                  </TouchableOpacity>
                </View>
              </Animated.View>
              <Animated.View
                style={[
                  {
                    overflow: 'hidden',
                    width: '100%',
                    height: this.state.ViewPassword,
                    opacity: interpo,
                  },
                  this.state.showOverflow && styles.overflowV,
                ]}>
                <View style={styles.passwordRecovery}>
                  <Text style={styles.textPasswordRecovery}>
                    Réinitialisez votre mot de passe
                  </Text>
                  <Text style={styles.textPasswordDesc}>
                    Entrez votre adresse e-mail pour recevoir un e-mail de
                    réinitialisation de mot de passe
                  </Text>
                </View>
                <FlatInput
                  placeholder="Adresse E-mail"
                  secureTextEntry={false}
                  value={this.state.email_reset}
                  onChangeText={email_reset => {
                    this.setState({email_reset}, () => {
                      this.form_validation('resetpasword');
                    });
                  }}
                  onSubmitEditing={() => {}}
                />
                <View style={styles.loginInfo}>
                  <View></View>
                  <TouchableOpacity
                    activeOpacity={0.9}
                    onPress={() => {
                      if (this.state.showOverflow) {
                        this.setState({clickType: false}, function () {
                          this.setState({showOverflow: false}, () => {
                            slidePanel(this.state.ViewPassword, 0, 300);
                            slidePanel(this.state.ViewLogin, 160, 500);
                          });
                        });
                      }
                    }}>
                    <Text style={styles.textUnderline}>Se connecter</Text>
                  </TouchableOpacity>
                </View>
              </Animated.View>
              <View style={{marginTop: 60, marginBottom: 50, width: '100%'}}>
                {this.state.clickType ? (
                  <Buttons
                    loading={this.state.loadingresetPassword}
                    disabled={this.state.disabledresetPassword}
                    onPress={() => {
                      this.forgetPassWord();
                    }}
                    text={'ENVOYER'}
                  />
                ) : (
                  <Buttons
                    disabled={this.state.disabledLogin}
                    loading={this.state.loadingLogin}
                    onPress={() => {
                      this.CheckInfos_();
                    }}
                    text={'SE CONNECTER'}
                  />
                )}
              </View>
              <View style={styles.privacy}>
                <TouchableOpacity
                  activeOpacity={0.9}
                  onPress={() => {
                    this.props.navigation.navigate('Privacy');
                  }}>
                  <Text style={styles.textPrivacy}>Mentions légales</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  activeOpacity={0.9}
                  onPress={() => {
                    this.props.navigation.navigate('Help');
                  }}>
                  <Text style={[styles.textPrivacy, {marginLeft: 15}]}>
                    Aide ?
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </KeyboardAvoidingView>
        </View>
      </>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginBg: {
    resizeMode: 'cover',
    position: 'absolute',
    zIndex: 2,
  },
  loginForm: {
    position: 'relative',
    backgroundColor: '#faf9fcd9',
    zIndex: 3,
    borderRadius: 20,
    padding: 25,
    alignSelf: 'center',
    maxWidth: 500,
  },
  loginInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 15,
  },
  textUnderline: {
    fontSize: 12,
    fontWeight: '500',
    color: '#2B59F0',
    textDecorationLine: 'underline',
  },
  privacy: {
    position: 'absolute',
    bottom: Platform.OS === 'web' ? 0 : 20,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  textPrivacy: {
    fontSize: 12,
    fontWeight: '500',
    color: '#A5A7B4',
    textDecorationLine: 'underline',
    alignSelf: 'center',
  },
  passwordRecovery: {
    marginBottom: 20,
  },
  textPasswordRecovery: {
    color: '#1E202C',
    fontSize: 18,
    fontWeight: '500',
  },
  textPasswordDesc: {
    color: '#2E303B',
    fontSize: 12,
    fontWeight: '400',
    marginTop: 15,
  },
  overflowV: {
    overflow: 'visible',
  },
  logoHolder:
  {
    height:60,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  }
});
const mapStateToProps = state => {
  return {
    dimensions: state.dimensions.dimensions,
    user: state.user.user,
  };
};
export default connect(mapStateToProps, {
  setUser,
  clientUser,
  setDimensions,
  setSecrets,
  setTracks,
  setLiveTrack,
  setPlaylist,
  setTrackState,
  setCurrentTrack,
  activePlayer
})(Login);
