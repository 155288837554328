
import {SETLIVE} from "../actions/Types"
const INTIAL_STATE=
{
    liveTrack:null,
}
export default (state=INTIAL_STATE,action)=>{

    switch (action.type) {
        case SETLIVE:
            return {...INTIAL_STATE,liveTrack:action.liveTrack}
        default:
            return state;
    }
}