

import {
    StyleSheet,
    Dimensions, Platform
} from 'react-native';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styleG = StyleSheet.create({

    c_container:
    {
        paddingLeft: 30,
        paddingRight: 30,
        width: '100%',
        display: 'flex',
        flexDirection: "column",
        overflow: 'hidden',
        maxHeight: Platform.OS == 'web' ? '100vh' : '100%',
        flex:1
    },
    container_:
    {
        height: "100%",
        width: "100%",
        flex: 1,
        flexDirection: 'column',
        backgroundColor: "#FAF9FC",
        maxHeight: Platform.OS == 'web' ? '100vh' : '100%',
    },
    titreH4:
    {
        fontSize: 14,
        color: "#1E202C",
        textTransform: "uppercase",
    },
    titreH4Border:
    {
        borderLeftWidth: 4,
        borderLeftColor: "#DE1F41",
        paddingLeft: 15,
        paddingTop: 5,
        paddingBottom: 5,
    },
    hideDetails:
    {
        width: 36,
        height: 36,
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        backgroundColor: "#202227",
        borderRadius: 20,
        marginBottom: 20,
        marginTop: 20
    },
    webcontainer:
    {
        maxWidth: 1200, alignSelf: 'center',
    },

    screenTitle:
    {
        fontSize: 16,
        fontWeight: "500",
        alignSelf: "center",
        marginLeft: 10,
    },
    screenheader: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 15,
        paddingBottom: 15,
    },
    screenheaderText:{
        color:'#1E202C',
        fontSize:18,
        marginLeft: 10,
        fontWeight:'bold'
    },
    serchGroup: {
        width: '100%',
        height: 36,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: 15,
        overflow: 'hidden',
        backgroundColor: '#fff',
    },
    serchGroupInput: {
        flex: 1,
        height: '100%',
        fontSize: 11,
        paddingRight: 10,
    },
    searchLoader: {
        flex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default styleG;
