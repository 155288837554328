
import {TRACKS} from "../actions/Types"
const INTIAL_STATE=
{
    tracks:[],
}
export default (state=INTIAL_STATE,action)=>{

    switch (action.type) {
        case TRACKS:
            return {...INTIAL_STATE,tracks:action.tracks}
        default:
            return state;
    }
}