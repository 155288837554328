import { createStackNavigator,TransitionPresets} from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';
import Login from "../screens/Login";
import Podcasts from "../screens/Podcasts";
import Profile from "../screens/Profile";
import News from "../screens/News";
import Register from "../screens/Register";
import Privacy from "../screens/Privacy";
import GPlayer from "../screens/GPlayer";
import Splash from "../screens/Splash";
import ResetPassWord from "../screens/ResetPassWord";
import Testscreen from "../screens/Testscreen";
import PlayLists from "../screens/PlayLists";
import Help from "../screens/Help";
const screens ={
    /*  Testscreen: { screen: Testscreen ,
        navigationOptions :{
            title:""
        },
    },   */
    Splash: { screen: Splash ,
        navigationOptions :{
            title:""
        },
    }, 
    
    Login: { screen: Login ,
        navigationOptions :{
            title:""
        },
        
    }, 
  
    PlayLists: { screen: PlayLists ,
        navigationOptions :{
            title:""
        },
    },
    Profile: { screen: Profile ,
        navigationOptions :{
            title:""
        },
    }, 
    News: { screen: News ,
        navigationOptions :{
            title:""
        },
    }, 

    Register: { screen: Register ,
        navigationOptions :{
            title:""
        },
    }, 
    GPlayer: { screen: GPlayer ,
        navigationOptions :{
            title:""
        },
    }, 

    Podcasts: { screen: Podcasts ,
        navigationOptions :{
            title:""
        },
    },
    Privacy: { screen: Privacy ,
        navigationOptions :{
            title:""
        },
    }, 
    Help: { screen: Help ,
        navigationOptions :{
            title:""
        },
    }, 
    ResetPassWord: { screen: ResetPassWord ,
        navigationOptions :{
            title:""
        },
    },  
    
}
 const defaultNavigationOptions ={
    headerTransparent: true,
    headerStyle :{backgroundColor: 'transparent' },
    headerShown:null,
    ...TransitionPresets.SlideFromRightIOS,
} 
const RootNavigator  = createStackNavigator(screens,{defaultNavigationOptions});
  
export default createAppContainer(RootNavigator);
