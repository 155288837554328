import React, {Component} from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  FlatList,
  Text,
  Platform,
  RefreshControl,
  Image,
  TextInput,
  ActivityIndicator
} from 'react-native';

import styleG from '../assets/style/styleG';
import {BoxItem, Loader, NoConnection} from '../components';
import Footer from '../components/Footer';
import Header from '../components/Header';
import {dimensions, formatDuration, switchTrack, apiUrl} from '../Dev';
import {setTracks, setTrackState, activePlayer,setCurrentTrack} from '../actions';
import {connect} from 'react-redux';
import TrackPlayer from '../services/TrackPlayer';
import axios from 'axios';
import MessageBox from '../common/MessageBox';
import {Mic, Play,Search} from '../assets/icons';
import {ControlBtn} from '../common';

const flatListHeader = (playList,pause,press=()=>{}) => {
  return (
    <View
      style={[styles.currentPlaylist]}>
      <Image
        style={[styles.currentPlaylistImg]}
        source={{uri: playList.image_url}}
      />
      <View style={[styles.currentPlaylistInfo]}>
        <Text style={[styles.currentPlaylistName]} numberOfLines={2}>
          {playList.name}
        </Text>
        <View style={[styles.flexContent]}>
          <Text style={[styles.currentPlaylistCount]}>
            {playList.podcasts_count} épisodes
          </Text>
          <ControlBtn
            size={30}
            onPress={press}>
            <Play
              pause={pause}
              color={'#fff'}
            />
          </ControlBtn>
        </View>
      </View>
    </View>
  );
};
class Podcasts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listHeigh: 180,
      filtredData: [],
      showBoxMsg: false,
      boxMsg: '',
      boxColor: '#28a745',
      PodLoader: false,
      noConnection: false,
      refreshList: false,
      playlist: {
        podcasts_count: 0,
        image_url: null,
        name: '',
      },
      seachVal:'',
      searchLoader: false,
    };
  }
  Item_(item) {
    return (
      <BoxItem titre={item.titre} duration={item.duration} img={item.cover} />
    );
  }
  componentDidMount() {
    this.checkList();
  }
  componentDidUpdate(prevProps){
    if(this.props.client){
       if(this.props.playlist?.id != prevProps.playlist?.id)
       {
        this.checkList();
       }
    }
 }
  checkList() {
    let id = -1;
    if (this.props.playlist) {
      id = this.props.playlist.id;
    } else if (this.props.client && this.props.client.count_playlists === 1) {
      id = this.props.client.playlist_id;
    }
    if (id === -1) return false;
    this.gtePodcasts(id);
  }
  gtePodcasts(id) {
    if (this.props.secret) {
      if (!this.state.refreshList) {
        this.setState({PodLoader: true});
      }
      this.setState({noConnection: false});
      axios
        .get(`${apiUrl}${id}/get-podcasts`, {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            Authorization: 'Bearer ' + this.props.secret.access_token,
          },
        })
        .then(response => {
          if (response.data.status == 'error') {
            this.setState({noConnection: true});
            /* stopUser(this.props.navigation, response.data.status);
                    this.setState({ boxColor: '#dc3545', boxMsg: response.data.messages.join('\n') }, () => {
                        this.setState({ showBoxMsg: true });
                    }); */
          } else if (response.data.status == 'success') {
            this.addTracks(response.data);
            this.setState({
              playlist: {
                podcasts_count: response.data.data.podcasts_count,
                image_url: response.data.data.image_url,
                name: response.data.data.name,
              },
            });
          }
          this.setState({PodLoader: false, refreshList: false});
        })
        .catch(error => {
          (!error.response || error.response.status == 404 || error.response.status == 400)
            this.setState({noConnection: true});
          this.setState({PodLoader: false, refreshList: false}); 
        });
    }
  }
  addTracks(customData) {
    let pushedList = [];
    customData.data.podcasts.data.forEach(element => {
      const el = element.podcast.data;
      pushedList.push({
        id: parseInt(el.id),
        url: el.audio_url ? el.audio_url : null,
        title: el.name,
        artist: '',
        artwork: el.image_url
          ? el.image_url
          : this.props.client
          ? this.props.client.current_show.image_url
          : null,
        duration: el.duration,
        started_at: '',
        end_at: '',
        isLive: false,
        playLoader: false,
      });
    });
    this.props.setTracks(pushedList);
    this.setState({filtredData:pushedList});
  }
  async PlayTrack(id) {
    var currentTrack = this.props.currentTrack;
    var isLiveId = this.props.currentTrack.id;
    let track = null,
      pos;
    if (isLiveId != -2 && id == this.props.currentTrack.id) {
      this.props.navigation.navigate('GPlayer');
    } else {
      pos = this.props.tracks.findIndex(element => element.id == id);
      if (pos > -1) {
        track = this.props.tracks[pos];
      }
      if (track) {
        this.props.activePlayer(1, true);
        currentTrack = {
          id: track.id,
          url: track.url,
          title: track.title,
          artist: track.artist,
          artwork: track.artwork,
          duration: track.duration,
          isLive: false,
          started_at: track.started_at,
          end_at: track.end_at,
        };
       
        if (Platform.OS != 'web') {
          let queue = await TrackPlayer.getQueue();
          if (queue.length == 0 || this.props.currentTrack.id == -2|| !this.props.trackState) {
            switchTrack(pos, this.props.tracks);
          } else{
            await TrackPlayer.skip(pos);
          }
        } else {
          if (!TrackPlayer.isUrlSet()) switchTrack(id, this.props.tracks);
          else TrackPlayer.play(track.url);
        }
        this.props.setTrackState(true);
        this.props.setCurrentTrack(currentTrack);
        !this.props.trackState && TrackPlayer.play();
        this.props.navigation.navigate('GPlayer');
      }
    }
  }
  search(text) {
    this.setState({ seachVal: text });
    setTimeout(() => {
        if (this.state.seachVal === text) {
            const results = [];
            this.setState({ searchLoader: true })
            this.props.tracks.map((element) => {
                var c = element.title;
                if (c.toUpperCase().includes(text.toUpperCase()))
                    results.push(element);
            });
            this.setState({ filtredData: results }, () => {
                setTimeout(() => {
                    this.setState({ searchLoader: false });
                }, 500);
            });
        }
    }, 600);
}
  render() {
    return (
      <>
        <MessageBox
          bg={this.state.boxColor}
          msg={this.state.boxMsg}
          show={this.state.showBoxMsg}
          closeBox={() => {
            this.setState({showBoxMsg: false});
          }}
        />
        {!this.state.PodLoader ? (
          <View style={[styleG.container_]}>
            {/* Header */}
            <Header
              back
              goTo={() => {
                this.props.navigation.navigate('PlayLists');
              }}
              navigation={this.props.navigation}
            />
            {/* Header */}
            {!this.state.noConnection ? (
              <>
                <View
                  style={[
                    {maxHeight: this.props.dimensions.height},
                    styles.flatStyle,
                    styleG.webcontainer,
                  ]}>
                  <View
                    style={[
                      styleG.screenheader,
                      Platform.OS == 'web' && styles.p_l_r,
                    ]}>
                    <Mic color={'#1E202C'} />
                    <Text style={styleG.screenheaderText}>Podcasts</Text>
                  </View>
                  <View style={Platform.OS == 'web' && styles.p_l_r}>
                  <View style={styleG.serchGroup}>
                            <TextInput
                                style={[styleG.serchGroupInput]}
                                allowFontScaling={false}
                                placeholder={'Recherche'}
                                autoCorrect={false}
                                autoCapitalize="none"
                                onChangeText={seachVal => {
                                    this.search(seachVal);
                                }}
                                value={this.state.seachVal}
                                placeholderStyle={{ fontFamily: 'Mulish' }}
                                autoFocus={false}
                                placeholderTextColor={'#656565'}
                            />
                            <Search />
                        </View>
                  </View>
                  {!this.state.searchLoader?<SafeAreaView style={{flex: 1}}>
                    <FlatList
                      dataSet={{item: 'listPadding'}}
                      data={this.state.filtredData}
                      ref={ref => {
                        this.flatListRef = ref;
                      }}
                      renderItem={({item}) => {
                        return (
                          <BoxItem
                            titre={item.title}
                            duration={formatDuration(item.duration)}
                            img={item.artwork}
                            controlPress={() => {
                              this.PlayTrack(item.id);
                            }}
                            currentTrack={
                              this.props.currentTrack.id == item.id
                                ? true
                                : false
                            }
                          />
                        );
                      }}
                      keyExtractor={item => item.id}
                      style={{
                        paddingLeft: Platform.OS == 'web' ? 30 : 0,
                        paddingRight: Platform.OS == 'web' ? 30 : 0,
                      }}
                      refreshControl={
                        <RefreshControl
                          refreshing={this.state.refreshList}
                          onRefresh={() => {
                            this.setState({refreshList: true}, () => {
                              this.checkList();
                            });
                          }}
                          colors={['#181A20', '#DE1F41']}
                          tintColor={'#fff'}
                          progressViewOffset={150}
                          progressBackgroundColor={'#fff'}
                        />
                      }
                      ListHeaderComponent={flatListHeader(this.state.playlist,(this.props.currentTrack.isLive ? false : this.props.trackState)
                      ,()=>{
                        if (this.props.tracks.length > 0) {
                          if (this.props.trackState && !this.props.currentTrack.isLive) {
                            this.props.navigation.navigate('GPlayer');
                            return false;
                          }
                          this.PlayTrack(this.props.tracks[0].id);
                        }
                      })}
                    />
                  </SafeAreaView>: <View style={styleG.searchLoader}>
                            <ActivityIndicator size="small" color="#DE1F41" />
                        </View>}
                </View>
              </>
            ) : (
              <NoConnection />
            )}
            <Footer navigation={this.props.navigation} active={1} />
          </View>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}
const styles = StyleSheet.create({
  podPlayer: {
    width: Platform.OS == 'web' ? '100%' : dimensions.width,
    height: Platform.OS == 'web' ? '100%' : dimensions.height,
    position: Platform.OS == 'web' ? 'fixed' : 'absolute',
    zIndex: 99,
    top: 0,
    backgroundColor: 'red',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  hideDetails: {
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: '#202227',
    left: 30,
    top: 90,
    zIndex: 10,
    borderRadius: 20,
    transform: [
      {
        rotate: '-90deg',
      },
    ],
  },
  flatStyle: {
    flex: 1,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
  },
  p_l_r: {
    paddingRight: 45,
    paddingLeft: 45,
  },
  m_l_r: {
    marginRight: 45,
    marginLeft: 45,
  },
  currentPlaylist: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#fff',
    borderWidth: 3,
    borderColor: '#ebebeb63',
    borderRadius: 5,
  },
  currentPlaylistImg: {
    width: 120,
    height: 120,
    borderRadius: 2,
  },
  currentPlaylistInfo: {
    marginLeft: 15,
    flex: 1,
  },
  currentPlaylistName: {
    color: '#1E202C',
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 15,
  },
  currentPlaylistCount: {
    color: '#737992',
    fontSize: 9,
    fontWeight: '500',
    flex: 1,
  },
  flexContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 10,
  },
});
const mapStateToProps = state => {
  return {
    tracks: state.tracks.tracks,
    infos_user: state.infos.infos,
    trackState: state.track_state.trackStatus,
    currentTrack: state.track_current.currentTrack,
    dimensions: state.dimensions.dimensions,
    secret: state.secret.secret,
    playlist: state.playList.playlist,
    client: state.client.client,
  };
};
export default connect(mapStateToProps, {
  setTracks,
  setTrackState,
  activePlayer,
  setCurrentTrack
})(Podcasts);
