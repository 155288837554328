
import React, { useState } from 'react';
import { TextInput, View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { Check } from "../assets/icons";
const CheckBox = (props) => {
  return (
    <View style={styles.checkHolder}>
      <TouchableOpacity style={styles.able} activeOpacity={0.9} onPress={() => { props.onChange(!props.value) }}>

      </TouchableOpacity>
      <View style={styles.checkMark}>
        {props.value && <View style={{ zIndex: 1 }}><Check color={'#1E202C'}/></View>}
      </View>
      {props.label && <Text style={styles.checkLabel}>{props.label}</Text>}
    </View>
  );
};
const styles = StyleSheet.create({
  checkHolder:
  {
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: "row",
  },
  checkMark:
  {
    width: 18,
    height: 18,
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#1E202C",
    borderRadius: 4,
    position: "relative",
  },
  able:
  {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    zIndex: 2,
  },
  checkLabel: {
    fontSize: 12,
    fontWeight: "500",
    color: '#1E202C',
    marginLeft: 15,
  }

});

export { CheckBox }